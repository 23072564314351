import { jsPDF } from "jspdf";
import autoTable, { applyPlugin } from "jspdf-autotable";
import moment from "moment";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import Button from "../../../components/button/Button";
import { BillingLogo, Invoice } from "../../../services/BillingService";
import { IFirm } from "../../../types/types";
import { Attachment } from "../../../factories/emails/models/send-email.model";

applyPlugin(jsPDF);

const discountTypes = {
  "%": "89EDC863-9055-479B-AD08-554C0418A59F",
  $: "771B703A-5E94-4271-988A-206C0B48F7A8",
};

function getImageSize(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = reject;
    img.src = url;
  });
}

const PDFPreview: FC<Props> = ({
  invoice,
  firm,
  paymentsRecieved,
  taxName,
  logo,
  setInvoiceFile,
  sendInvoice,
}) => {
  const [pdfDataUrl, setPdfDataUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const createImage = (url: string) => {
    const image = document.createElement("img");
    image.style.objectFit = "cover";
    image.style.width = "50px";
    image.style.height = "50px";
    image.src = url;
    return image;
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const generatePDF = async () => {
    const doc = new jsPDF();

    doc.setProperties({
      title: "Invoice",
    });

    const getMaxWidth = () => {
      const labelInvoiceNoWidth =
        (doc.getStringUnitWidth("Invoice No.: ") * doc.getFontSize()) /
        doc.internal.scaleFactor;

      const valueInvoiceNoWidth =
        (doc.getStringUnitWidth(`${invoice?.invoiceNo || 0}`) *
          doc.getFontSize()) /
        doc.internal.scaleFactor;

      const labelInvoiceDateWidth =
        (doc.getStringUnitWidth("Invoice date: ") * doc.getFontSize()) /
        doc.internal.scaleFactor;

      const valueInvoiceDateWidth =
        (doc.getStringUnitWidth(
          `${moment(invoice?.invoiceDate).format("MMM, D YYYY")}`,
        ) *
          doc.getFontSize()) /
        doc.internal.scaleFactor;

      const labelDueDateWidth =
        (doc.getStringUnitWidth("Due date: ") * doc.getFontSize()) /
        doc.internal.scaleFactor;

      const valueDueDateWidth =
        (doc.getStringUnitWidth(
          `${moment(invoice?.dueDate).format("MMM, D YYYY")}`,
        ) *
          doc.getFontSize()) /
        doc.internal.scaleFactor;

      return Math.max(
        labelInvoiceNoWidth + valueInvoiceNoWidth,
        labelInvoiceDateWidth + valueInvoiceDateWidth,
        labelDueDateWidth + valueDueDateWidth,
      );
    };

    const renderBoldLabel = (
      label: string,
      value: string,
      y: number,
      isBoldBoth?: boolean,
      customX?: number,
      middleValue?: string,
    ) => {
      doc.setFont("helvetica", "bold");
      const labelWidth =
        (doc.getStringUnitWidth(label + ": ") * doc.getFontSize()) /
        doc.internal.scaleFactor;
      doc.setFont("helvetica", isBoldBoth ? "bold" : "normal");
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.getFontSize()) /
        doc.internal.scaleFactor;
      let middleValueWidth = 0;
      if (middleValue) {
        doc.setFontSize(9);
        middleValueWidth =
          (doc.getStringUnitWidth(middleValue) * doc.getFontSize()) /
          doc.internal.scaleFactor;
        doc.setFontSize(10);
      }

      const totalWidth = labelWidth + valueWidth + middleValueWidth;

      const startX = customX
        ? doc.internal.pageSize.width - customX - 10
        : doc.internal.pageSize.width - 10 - totalWidth;

      doc.setFont("helvetica", "bold");
      doc.text(label + ":", startX, y);

      if (middleValue) {
        doc.setFontSize(9);
        doc.setFont("helvetica", "normal");
        doc.text(middleValue || "", startX + labelWidth, y);
        doc.setFontSize(10);
      }

      doc.setFont("helvetica", isBoldBoth ? "bold" : "normal");
      doc.text(value, startX + labelWidth + middleValueWidth, y);
    };
    doc.setFontSize(10);
    doc.setFont("helvetica", "", "normal");

    const subtotal = () => {
      let value = 0;
      invoice?.invoiceItem?.forEach((item) => (value += item.amount));
      return value;
    };

    const discountValue = () => {
      if (invoice?.discountTypeId === discountTypes.$.toLowerCase()) {
        return invoice?.discount || 0;
      }
      if (invoice?.discountTypeId === discountTypes["%"].toLowerCase()) {
        return (subtotal() * (invoice?.discount || 0)) / 100;
      }
      return 0;
    };

    const taxValue = () => {
      if (invoice?.isIncluded) {
        const tax: number = (invoice?.tax || 0) / 100 + 1;
        const subtotalWithoutTax = invoice?.invoiceItem.reduce(
          (acc, item) => acc + item.amount / tax,
          0,
        );
        return (
          (((subtotalWithoutTax || 0) - (discountValue() || 0)) *
            (invoice?.tax || 0)) /
          100
        );
      }
      return (
        (((subtotal() || 0) - (discountValue() || 0)) * (invoice?.tax || 0)) /
        100
      );
    };

    const rightAlignText = ({ text, y }: { text: string; y: number }) => {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.getFontSize()) /
        doc.internal.scaleFactor;
      const textOffset = doc.internal.pageSize.width - 10 - textWidth;
      doc.text(text, textOffset, y);
    };

    const checkAndAddPage = (y: number) => {
      if (y > doc.internal.pageSize.height - 20) {
        doc.addPage();
        return 10;
      }
      return y;
    };

    const maxImageHeight = 20; // Maximum image height
    const maxImageWidth = 60; // Maximum image width
    let imgWidth = 0;
    let imgHeight = 0;

    if (logo?.imageURI) {
      const size = await getImageSize(logo.imageURI);
      const imageRatio = size.width / size.height;

      imgHeight = size.height > maxImageHeight ? maxImageHeight : size.height;
      imgWidth = imgHeight * imageRatio;

      if (imgWidth > maxImageWidth) {
        imgWidth = maxImageWidth;
        imgHeight = imgWidth / imageRatio;
      }
    }

    // Add company information
    let y = 10;

    if (logo?.imageURI && imgWidth && imgHeight) {
      const imageData = createImage(logo.imageURI);
      doc.addImage(imageData, "JPEG", 10, y, imgWidth, imgHeight);
    }

    doc.setFontSize(18).setFont("helvetica", "", 700);
    rightAlignText({ text: "INVOICE", y });
    y += 10;

    const companyDetails = [
      `${truncateText(firm?.displayName || "", 50)}`,
      `${truncateText(firm?.locations?.[0]?.locationAddress?.line1 || "", 50)}`,
      `${truncateText(
        `${firm?.locations?.[0]?.locationAddress?.city}, ${firm?.locations?.[0]?.locationAddress?.region} ${firm?.locations?.[0]?.locationAddress?.postalCode}`,
        150,
      )}`,
      `${truncateText(firm?.phoneNumbers?.[0]?.number || "", 50)}`,
    ];
    companyDetails.forEach((detail, index) => {
      if (detail) {
        if (index === 0) {
          doc.setFontSize(13).setFont("helvetica", "", "bold");
          rightAlignText({
            text: detail,
            y,
          });
        } else {
          doc.setFontSize(10).setFont("helvetica", "", "normal");
          rightAlignText({
            text: detail,
            y,
          });
        }
        rightAlignText({
          text: detail,
          y,
        });
        y += 5;
      }
    });

    if (imgHeight > 50) {
      y += imgHeight + 15 - y;
    } else {
      y += 5;
    }

    doc.setDrawColor(190, 190, 190);
    const pageWidth = doc.internal.pageSize.width;
    doc.line(0, y, pageWidth, y);
    y += 10;
    // Add customer details
    let leftY = y;
    let rightY = y;

    y = checkAndAddPage(y);
    doc
      .setFontSize(10)
      .setFont("helvetica", "", "normal")
      .setTextColor("#5E5D5D");
    doc.text("BILL TO", 10, y).setTextColor(0);
    y += 5;
    leftY += 5;
    rightY += 5;
    doc.setFontSize(10).setFont("helvetica", "", "bold");
    doc.text(`${invoice?.customer?.name || ""}`, 10, leftY);
    doc.setFontSize(10).setFont("helvetica", "bold");
    leftY += 5;
    y += 5;
    const customX = getMaxWidth();

    renderBoldLabel(
      "Invoice No.",
      `${invoice?.invoiceNo || 0}`,
      rightY,
      false,
      customX,
    );

    rightY += 5;
    y += 5;
    doc.setFontSize(10).setFont("helvetica", "", "bold");

    renderBoldLabel(
      "Invoice date",
      `${moment(invoice?.invoiceDate).format("MMM, D YYYY")}`,
      rightY,
      false,
      customX,
    );
    rightY += 5;
    renderBoldLabel(
      "Due Date",
      `${moment(invoice?.dueDate).format("MMM, D YYYY")}`,
      rightY,
      false,
      customX,
    );
    doc.setFont("helvetica", "bold");
    invoice?.invoiceCustomers?.forEach((item) => {
      leftY = checkAndAddPage(leftY);
      doc.text(item?.customers?.name || "", 10, leftY);
      leftY += 5;
      y += 5;
    });
    doc.setFontSize(10).setFont("helvetica", "", "normal");
    doc.text(`${invoice?.customer?.addresses?.[0]?.line1 || ""}`, 10, leftY);
    leftY += 5;
    y += 5;
    doc.setFont("helvetica", "normal");
    doc.text(
      `${invoice?.customer?.addresses?.[0]?.city || ""} ${
        invoice?.customer?.addresses?.[0]?.region || ""
      } ${invoice?.customer?.addresses?.[0]?.postalCode || ""}`,
      10,
      leftY,
    );

    leftY += 10;
    y += 10;

    // Add table rows
    y = doc.lastAutoTable.finalY || y - 20;
    const tableData =
      invoice?.invoiceItem?.map((item) => [
        item?.billingService?.name || "",
        truncateText(item?.description || "", 30),
        item?.quantity.toString() || "",
        `$${(item?.rate || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        `$${(item?.amount || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      ]) || [];

    const tableColumns = [
      "Service Name",
      "Description",
      "Qty",
      "Rate",
      "Amount",
    ];
    y = checkAndAddPage(y + 20);
    doc.setTextColor(0);
    autoTable(doc, {
      startY: y,
      head: [tableColumns],
      margin: { left: 0, bottom: 0 },
      body: tableData,
      theme: "grid",
      tableWidth: doc.internal.pageSize.width,
      styles: {
        cellWidth: "auto",
        textColor: [0, 0, 0],
      },
      headStyles: {
        fillColor: [85, 85, 85],
        textColor: [255, 255, 255],
        cellPadding: { left: 10, vertical: 2.5 },
      },
      columnStyles: {
        0: {
          cellWidth: "auto",
          lineWidth: 0,
          fontStyle: "bold",
          font: "helvetica",
          cellPadding: { left: 10, vertical: 1.5 },
        },
        1: {
          cellWidth: "auto",
          lineWidth: 0,
          font: "helvetica",
          cellPadding: { left: 10, top: 1.5 },
        },
        2: {
          cellWidth: "auto",
          lineWidth: 0,
          font: "helvetica",
          cellPadding: { left: 10, top: 1.5 },
        },
        3: {
          cellWidth: "auto",
          lineWidth: 0,
          font: "helvetica",
          cellPadding: { left: 10, top: 1.5 },
        },
        4: {
          cellWidth: "auto",
          lineWidth: 0,
          font: "helvetica",
          cellPadding: { left: 10, top: 1.5 },
        },
      },
    });
    y = checkAndAddPage(doc.lastAutoTable.finalY);
    doc.line(0, y, doc.internal.pageSize.width, y);
    y = checkAndAddPage(y + 20);

    renderBoldLabel(
      "Subtotal",
      `$${(subtotal() || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      y,
    );
    y = checkAndAddPage(y + 5);
    if (discountValue()) {
      renderBoldLabel(
        "Discount",
        `$${(discountValue() || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        y,
      );
      y = checkAndAddPage(y + 5);
    }

    const includesOn = `$${(
      subtotal() -
      taxValue() -
      discountValue()
    ).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

    const taxValueString = `$${(taxValue() || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;

    renderBoldLabel(
      `${taxName}`,
      taxValueString,
      y,
      false,
      undefined,
      invoice?.isIncluded ? `(Includes ${taxName} on ${includesOn}) ` : "",
    );
    y = checkAndAddPage(y + 5);

    // Draw line after tax
    doc.line(
      ((doc.internal.pageSize.width - 10) / 4) *
        (invoice?.isIncluded ? 2.5 : 3),
      y,
      doc.internal.pageSize.width - 10,
      y,
    );
    y = checkAndAddPage(y + 8);

    renderBoldLabel(
      "Total",
      `$${(invoice?.total || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      y,
    );
    y = checkAndAddPage(y + 5);

    // Draw line after total
    doc.line(
      ((doc.internal.pageSize.width - 10) / 4) *
        (invoice?.isIncluded ? 2.5 : 3),
      y,
      doc.internal.pageSize.width - 10,
      y,
    );
    y = checkAndAddPage(y + 8);
    if (paymentsRecieved) {
      renderBoldLabel(
        "Payment received",
        `$${(paymentsRecieved || 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        y,
      );
      y = checkAndAddPage(y + 10);
    }
    renderBoldLabel(
      "Balance due",
      `$${((invoice?.total || 0) - (paymentsRecieved || 0)).toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      )}`,
      y,
      true,
    );

    return doc.output("datauristring");
  };

  const handleOpenPDF = async () => {
    const pdfUrl = await generatePDF();
    setPdfDataUrl(pdfUrl);
    /*setInvoiceFile &&
      setInvoiceFile({
        content: pdfUrl,
        content_type: "datauristring",
        filename: "Invoice",
      });*/
    setShowPopup(true);
  };

  return (
    <div>
      <Button
        label={"View invoice"}
        onClick={handleOpenPDF}
        extraClasses={"normal-case"}
      />
      {showPopup && (
        <div
          className={`modal ${showPopup ? "modal-open" : "modal-close"}`}
          onClick={() => setShowPopup(false)}>
          <div
            className={
              "modal-box max-h-[80vw] min-h-fit min-w-[80vw] max-w-[80vw]"
            }
            onClick={(e) => e.stopPropagation()}>
            <iframe
              title={"Invoice"}
              src={pdfDataUrl}
              className={"max-h-full min-w-full"}
              height={"800px"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  invoice?: Invoice;
  firm?: IFirm | null;
  paymentsRecieved: number;
  taxName: string;
  logo: BillingLogo | undefined;
  setInvoiceFile?: Dispatch<SetStateAction<Attachment>>;
  sendInvoice?: boolean;
}

export default PDFPreview;
