import { ChevronDown } from "baseui/icon";
import classNames from "classnames";
import React, { FC, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { WorkTaskModel } from "../../factories/work/model/work-task.model";
import { StatusesList } from "../../features/works/dropdown/StatusesList";
import { getStatusColor } from "../../features/works/dropdown/utils";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { useUpdateWorkTaskMutation } from "../../slices/WorkTasksSlice";

type Props = {
  workTask: WorkTaskModel;
  workTypeId: string;
  length: number;
  index: number;
};

const StatusMenu: FC<Props> = ({ workTask, workTypeId, length, index }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [
    updateWorkTaskStatus,
    { isLoading: isLoadingWorkTask, data: updatedWorkTask },
  ] = useUpdateWorkTaskMutation();

  const handleChangeStatus = ({
    statusId,
    subStatusId,
  }: {
    statusId: string | null;
    subStatusId: string | null;
  }) => {
    updateWorkTaskStatus({
      ...workTask,
      statusId: statusId,
      subStatusId: subStatusId,
    });
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const getStatusName = () => {
    if (updatedWorkTask) {
      return updatedWorkTask?.subStatus
        ? updatedWorkTask?.subStatus?.subStatus
        : updatedWorkTask?.status?.name || "No status";
    }

    return workTask?.subStatus
      ? workTask?.subStatus?.subStatus
      : workTask?.status?.name || "No status";
  };

  const getDropdownPosition = () => {
    if (length > 3) {
      return length - index < 3 ? "dropdown-end" : "";
    } else {
      return "";
    }
  };

  return (
    <div
      ref={dropdownRef}
      className={classNames(
        "dropdown dropdown-right",
        isOpen && "dropdown-open",
        getDropdownPosition(),
      )}>
      <div tabIndex={0} onClick={() => setIsOpen(!isOpen)}>
        {isLoadingWorkTask ? (
          <div
            className={
              "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border-[1px] border-gray-200 bg-white px-1 py-0.5 text-sm font-semibold text-gray-800"
            }>
            <div className={"text-purple h-[10px] w-[10px] animate-spin"}>
              <AiOutlineLoading3Quarters size={10} />
            </div>
            <div>Loading...</div>
            <ChevronDown size={16} />
          </div>
        ) : (
          <div
            className={
              "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border border-[#EAEDF3A3] bg-[#F8F9FB] px-1 py-0.5 text-sm font-semibold text-gray-800"
            }>
            <div
              className={"flex h-[10px] w-[10px] rounded-full"}
              style={{
                backgroundColor: getStatusColor(
                  updatedWorkTask
                    ? updatedWorkTask?.status?.name || ""
                    : workTask?.status?.name || "",
                ),
              }}
            />
            <div className={"max-w-[140px] truncate"}>{getStatusName()}</div>
            <ChevronDown size={16} />
          </div>
        )}
      </div>
      <ul tabIndex={0} className={"dropdown-content"}>
        <StatusesList
          setIsOpen={setIsOpen}
          workTypeId={workTypeId}
          updateStatusWork={handleChangeStatus}
        />
      </ul>
    </div>
  );
};

export default StatusMenu;
