import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import * as devConfig from "../../app/configs/devConfig";
import { getAuthToken } from "../../app/globalState/GlobalAuthState";
import StopWatchSvg from "../../components/svg/stopwatch-icon.svg";
import { formatTime } from "../../constants/constants";
import useSignalR from "../../hooks/useSignalR";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  pauseTimer,
  startTimer,
  increaseDuration,
} from "../../services/TimerService";
import StartTimer from "../time/StartTimer";
import AddTimer from "./AddTimer";

const Timer = () => {
  const dispatch = useAppDispatch();

  const { timer, duration } = useAppSelector((state) => state.timerReducer);

  const [isOpenAddTimer, setIsOpenAddTimer] = useState(false);
  const [isOpenStartTime, setIsOpenStartTime] = useState(false);

  useSignalR(
    `${devConfig.getServerBaseUrlForEnv()}/signalRHub`,
    getAuthToken().Authorization,
  );

  const closeAddTimer = () => {
    setIsOpenAddTimer(false);
  };

  const onClickPauseTimer = () => {
    if (timer?.timerStatus === "paused") {
      dispatch(
        startTimer({
          workId: timer?.workId,
          customerId: timer?.contactId || timer?.businessContactId,
        }),
      );
    } else {
      dispatch(pauseTimer(timer?.id || ""));
    }
  };

  const onClickStopTimer = () => {
    dispatch(pauseTimer(timer?.id || ""));
    setIsOpenAddTimer(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (timer?.timerStatus === "started") {
      interval = setInterval(() => {
        dispatch(increaseDuration());
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer?.timerStatus]);

  const renderContent = () => {
    if (timer?.timerStatus === "started" || timer?.timerStatus === "paused") {
      return (
        <div className={"mr-[32px]  w-[140px] "}>
          <div className={"flex items-center justify-between"}>
            <div className={"text-white"}>
              {formatTime(duration, {
                isHours: true,
                isMinutes: true,
                isSeconds: true,
              })}
            </div>
            <div
              onClick={onClickPauseTimer}
              className={
                "flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full bg-blue-500 text-[14px] text-white"
              }>
              {timer?.timerStatus === "paused" ? (
                <FontAwesomeIcon icon={faPlay} />
              ) : (
                <FontAwesomeIcon icon={faPause} />
              )}
            </div>
            <div
              onClick={onClickStopTimer}
              className={
                "flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full bg-red-500 text-[14px] text-white"
              }>
              <FontAwesomeIcon icon={faStop} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          onClick={() => setIsOpenStartTime(true)}
          className={
            "mr-2 flex w-[43px] cursor-pointer items-center justify-center rounded-full p-2 hover:bg-purple-700"
          }>
          <img
            src={StopWatchSvg}
            alt={"Time"}
            className={"h-[27px] w-[27px]"}
          />
        </div>
      );
    }
  };

  return (
    <>
      {renderContent()}
      {isOpenAddTimer && (
        <AddTimer isOpen={isOpenAddTimer} closeModal={closeAddTimer} />
      )}
      {isOpenStartTime && (
        <StartTimer
          isOpen={isOpenStartTime}
          closeModal={() => setIsOpenStartTime(false)}
        />
      )}
    </>
  );
};

export default Timer;
