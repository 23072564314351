import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Button from "../../../components/button/Button";
import { InputFile } from "../components/InputFile";
import {
  useLazyGetLinkedWorkQuery,
  useReplyEmailMutation,
  useSendEmailMutation,
} from "../../../slices/EmailsSlice";
import { useAppSelector } from "../../../redux/redux";
import { Email, EmailTo } from "../../../factories/emails/models/emails.models";
import { HiPlus, HiXMark } from "react-icons/hi2";
import { Attachment } from "../../../factories/emails/models/send-email.model";
import { v4 } from "uuid";
import { FaFile } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment/moment";
import { Editor } from "react-draft-wysiwyg";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromHTML,
  convertToRaw,
  DraftDecoratorComponentProps,
  EditorState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { IEmail } from "../../../types/types";

type Props = {
  isActive: boolean;
  onClose: () => void;
  isReply?: boolean;
  isReplyAll?: boolean;
  email?: Email;
  customersEmail?: EmailTo[];
  selectedMail?: Email | null;
  bulk?: boolean;
  invoiceEmail?: string | null;
  invoiceFile?: Attachment;
};

const fileToBase64 = (file: File): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reader.abort();
      reject(error);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file specified"));
    }
  });
};
const CustomStyleComponent: React.FC<DraftDecoratorComponentProps> = (
  props,
) => {
  return (
    <span
      style={{
        marginLeft: "0.8ex",
        borderLeft: "1px solid #ccc",
        paddingLeft: "1ex",
      }}>
      {props.children}
    </span>
  );
};
const styleDecorator = {
  strategy: (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) => {
    contentBlock.findStyleRanges((character: any) => {
      const style = character.getStyle();
      return style && style.has("CUSTOM_STYLE");
    }, callback);
  },
  component: CustomStyleComponent,
};
const decorator = new CompositeDecorator([styleDecorator]);

export const ComposeEmailModal: FC<Props> = ({
  isActive,
  onClose,
  isReply,
  isReplyAll,
  email,
  customersEmail,
  selectedMail,
  bulk,
  invoiceEmail,
  invoiceFile,
}) => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const ccEmailRef = useRef<HTMLInputElement | null>(null);
  const bccEmailRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const addFileRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<(Attachment & { id: string })[]>([]);
  const { user } = useAppSelector((state) => state.appReducer);
  const [selectedEmails, setSelectedEmails] = useState<EmailTo[]>(
    email?.from || [],
  );
  const [selectedCcEmails, setSelectedCcEmails] = useState<EmailTo[]>(
    email?.cc || [],
  );
  const [selectedBccEmails, setSelectedBccEmails] = useState<EmailTo[]>(
    email?.bcc || customersEmail || [],
  );
  const [getLinkedWork, { data: workId }] = useLazyGetLinkedWorkQuery();

  useEffect(() => {
    if (selectedMail) {
      getLinkedWork({ threadId: selectedMail?.thread_id || "" });
    }
  }, [selectedMail]);

  const [mail, setMail] = useState("");
  const [ccMail, setCcMail] = useState("");
  const [bccMail, setBccMail] = useState("");
  const [subject, setSubject] = useState(email?.subject || "");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidCcEmail, setIsValidCcEmail] = useState(true);
  const [isValidBccEmail, setIsValidBccEmail] = useState(true);
  const [isOpenCc, setIsOpenCc] = useState(false);
  const [isOpenBcc, setIsOpenBcc] = useState(false);

  const blocksFromHTML = convertFromHTML(
    `${message}<br/> ${moment
      .unix(email?.date || 0)
      .format(
        "On ddd, MMM DD, YYYY аt hh:mm A",
      )}<br/><div style="margin:0 0 0 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">${
      email?.body
    }</div>`,
  );
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(contentState, decorator),
  );
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const htmlString = draftToHtml(rawContentState);

  const handleFileChange = async (file: File) => {
    const base64 = await fileToBase64(file);
    const id = v4();
    if (base64) {
      const attachment = {
        filename: file.name,
        content_type: file.type,
        content: base64,
        id: id,
      };
      setFiles((prev) => [...prev, attachment]);
    }
  };

  const [sendEmail, { isSuccess, isLoading: isLoadingCreate }] =
    useSendEmailMutation();
  const [replyEmail, { isSuccess: isSuccessReply, isLoading }] =
    useReplyEmailMutation();

  useEffect(() => {
    if (isSuccess || isSuccessReply) {
      onClose();
    }
  }, [isSuccess, isSuccessReply]);

  /*useEffect(() => {
    if (invoiceEmail) {
      setSelectedEmails((prev) => [...prev, { email: invoiceEmail }]);
    }
  }, [invoiceEmail]);*/

  const onSubmit = () => {
    if ((isReply || isReplyAll) && email) {
      const attachments: Attachment[] = files.map((item) => ({
        content: item.content,
        content_type: item.content_type,
        filename: item.filename,
      }));
      workId
        ? replyEmail({
            userId: user.id,
            body: {
              subject: subject,
              reply_to: selectedEmails,
              reply_to_message_id: email?.id || "",
              from: email?.from,
              body: `${message}<br/> ${moment
                .unix(email?.date)
                .format(
                  "On ddd, MMM DD, YYYY аt hh:mm A",
                )}<br/><div style="margin:0 0 0 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">${
                email?.body
              }</div>`,
              to: selectedEmails,
              cc: selectedCcEmails,
              bcc: selectedBccEmails,
              attachments: attachments,
            },
          })
        : replyEmail({
            userId: user.id,
            body: {
              subject: subject,
              reply_to: selectedEmails,
              thread_id: email.thread_id,
              from: email?.from,
              body: `${message}<br/> ${moment
                .unix(email?.date)
                .format(
                  "On ddd, MMM DD, YYYY аt hh:mm A",
                )}<br/><div style="margin:0 0 0 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex">${
                email?.body
              }</div>`,
              to: selectedEmails,
              cc: selectedCcEmails,
              bcc: selectedBccEmails,
              attachments: attachments,
            },
          });
    } else {
      const attachments: Attachment[] = files.map((item) => ({
        content: item.content,
        content_type: item.content_type,
        filename: item.filename,
      }));
      sendEmail({
        userId: user.id,
        body: {
          subject: subject,
          body: message,
          to: selectedEmails,
          cc: selectedCcEmails,
          bcc: selectedBccEmails,
          attachments: attachments,
          tracking_options: {
            opens: true,
            links: true,
            thread_replies: true,
            label: "",
          },
        },
      });
    }
  };
  const addEmail = () => {
    const isValidEmail =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(mail);
    if (isValidEmail) {
      if (selectedEmails.some((item) => item.email === mail)) {
        setMail("");
      } else {
        setSelectedEmails((prev) => [...prev, { email: mail }]);
        setMail("");
      }
    }
    setIsValidEmail(isValidEmail);
  };
  const addCcEmail = () => {
    const isValidCcEmail =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(ccMail);
    if (isValidCcEmail) {
      if (selectedCcEmails.some((item) => item.email === ccMail)) {
        setCcMail("");
      } else {
        setSelectedCcEmails((prev) => [...prev, { email: ccMail }]);
        setCcMail("");
      }
    }
    setIsValidCcEmail(isValidCcEmail);
  };
  const addBccEmail = () => {
    const isValidBccEmail =
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(bccMail);
    if (isValidBccEmail) {
      if (selectedBccEmails.some((item) => item.email === bccMail)) {
        setBccMail("");
      } else {
        setSelectedBccEmails((prev) => [...prev, { email: bccMail }]);
        setBccMail("");
      }
    }
    setIsValidBccEmail(isValidBccEmail);
  };

  return (
    <div
      className={classNames("modal", isActive ? "modal-open" : "modal-close")}>
      <div
        className={
          "ml-[5vw] mt-[5vh] max-h-[90vh] w-[85vw] overflow-y-auto rounded-xl bg-white p-0"
        }>
        <div
          className={
            "grid  grid-cols-[1fr_max-content] border-b border-gray-300 px-6 py-4 text-center text-xl font-semibold"
          }>
          <div>
            {isReply
              ? "Reply to email"
              : isReplyAll
              ? "Reply to all"
              : "Compose new email"}
          </div>
          <div
            className={"close-popup cursor-pointer text-end"}
            onClick={() => {
              onClose(),
                setSelectedEmails([]),
                setSelectedCcEmails([]),
                setSelectedBccEmails([]);
            }}>
            <img src={"/img/closse.png"} alt={""} />
          </div>
        </div>
        <div>
          <div className={"flex items-center justify-between px-4 pt-4"}>
            <div className={"w-full"}>
              <div className={"mb-5 grid grid-cols-[1fr_4fr] items-center"}>
                <div className={"text-[18px] font-semibold"}>From:</div>
                <div
                  className={"flex justify-between text-[16px] font-semibold"}>
                  {user.nylasToken.email}
                  <div className={"flex gap-2"}>
                    <div
                      className={"text-purple cursor-pointer font-bold"}
                      onClick={() => setIsOpenCc(!isOpenCc)}>
                      Cc
                    </div>
                    {!bulk && (
                      <div
                        className={"text-purple cursor-pointer font-bold"}
                        onClick={() => setIsOpenBcc(!isOpenBcc)}>
                        Bcc
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                <label className={"label"}>
                  <div className={"label-text"}>To:</div>
                </label>
                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "input input-bordered flex h-full w-[95%] flex-wrap items-center gap-1 py-2 text-[14px] font-semibold"
                    }
                    onClick={() => emailRef.current?.focus()}>
                    {selectedEmails.map((mail) => (
                      <div
                        className={
                          "flex items-center gap-1 rounded-[15px] border-[2px] border-[#7c66f0] px-2"
                        }>
                        {mail.email}
                        <HiXMark
                          onClick={() =>
                            setSelectedEmails((prev) =>
                              prev.filter((item) => item.email !== mail.email),
                            )
                          }
                          className={"cursor-pointer"}
                        />
                      </div>
                    ))}
                    <div>
                      <input
                        type={"text"}
                        name={"email"}
                        value={mail}
                        ref={emailRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === "Tab") {
                            addEmail();
                          }
                        }}
                        onChange={(e) => {
                          setMail(e.target.value);
                          setIsValidEmail(true);
                        }}
                        onBlur={addEmail}
                        className={"w-[100%] outline-none"}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      "bg-purple flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
                    }
                    onClick={addEmail}>
                    <HiPlus />
                  </div>
                </div>
              </div>
              <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                <div></div>
                {!isValidEmail && mail !== "" && (
                  <span className={"mb-2 text-sm font-bold text-error"}>
                    Invalid email address format
                  </span>
                )}
              </div>
              {isOpenCc && (
                <>
                  <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                    <label className={"label"}>
                      <div className={"label-text"}>Cc:</div>
                    </label>
                    <div className={"flex items-center justify-between"}>
                      <div
                        className={
                          "input input-bordered flex h-full w-[95%] flex-wrap items-center gap-1 py-2 text-[14px] font-semibold"
                        }
                        onClick={() => ccEmailRef.current?.focus()}>
                        {selectedCcEmails.map((ccMail) => (
                          <div
                            className={
                              "flex items-center gap-1 rounded-[15px] border-[2px] border-[#7c66f0] px-2"
                            }>
                            {ccMail.email}
                            <HiXMark
                              onClick={() =>
                                setSelectedCcEmails((prev) =>
                                  prev.filter(
                                    (item) => item.email !== ccMail.email,
                                  ),
                                )
                              }
                              className={"cursor-pointer"}
                            />
                          </div>
                        ))}
                        <div
                          className={
                            "flex w-full items-center justify-between"
                          }>
                          <input
                            type={"text"}
                            name={"cc"}
                            value={ccMail}
                            ref={ccEmailRef}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === "Tab") {
                                addCcEmail();
                              }
                            }}
                            onChange={(e) => {
                              setCcMail(e.target.value);
                              setIsValidCcEmail(true);
                            }}
                            onBlur={addCcEmail}
                            className={"w-[100%] outline-none"}
                          />
                        </div>
                      </div>
                      <div>
                        <div
                          className={
                            "close-popup flex w-8 cursor-pointer items-center justify-center"
                          }
                          onClick={() => setIsOpenCc(false)}>
                          <img src={"/img/closse.png"} alt={""} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                    <div></div>
                    {!isValidCcEmail && ccMail !== "" && (
                      <span className={"mb-2 text-sm font-bold text-error"}>
                        Invalid email address format
                      </span>
                    )}
                  </div>
                </>
              )}

              {(isOpenBcc || bulk) && (
                <>
                  <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                    <label className={"label"}>
                      <div className={"label-text"}>Bcc:</div>
                    </label>
                    <div className={"flex items-center justify-between"}>
                      <div
                        className={
                          "input input-bordered flex h-full w-[95%] flex-wrap items-center gap-1 py-2 text-[14px] font-semibold"
                        }
                        onClick={() => bccEmailRef.current?.focus()}>
                        {selectedBccEmails.map((bccMail) => (
                          <div
                            className={
                              "flex items-center gap-1 rounded-[15px] border-[2px] border-[#7c66f0] px-2"
                            }>
                            {bccMail.email}
                            <HiXMark
                              onClick={() =>
                                setSelectedBccEmails((prev) =>
                                  prev.filter(
                                    (item) => item.email !== bccMail.email,
                                  ),
                                )
                              }
                              className={"cursor-pointer"}
                            />
                          </div>
                        ))}
                        <div
                          className={
                            "flex w-full items-center justify-between"
                          }>
                          <input
                            type={"text"}
                            name={"bcc"}
                            value={bccMail}
                            ref={bccEmailRef}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === "Tab") {
                                addBccEmail();
                              }
                            }}
                            onBlur={addBccEmail}
                            onChange={(e) => {
                              setBccMail(e.target.value);
                              setIsValidBccEmail(true);
                            }}
                            className={"w-[100%] outline-none"}
                          />
                        </div>
                      </div>
                      <div>
                        {bulk ? (
                          <div
                            className={
                              "bg-purple flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-white"
                            }
                            onClick={addBccEmail}>
                            <HiPlus />
                          </div>
                        ) : (
                          <div
                            className={
                              "close-popup flex w-8 cursor-pointer items-center justify-center"
                            }
                            onClick={() => setIsOpenBcc(false)}>
                            <img src={"/img/closse.png"} alt={""} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={"my-2 grid grid-cols-[1fr_4fr] items-center"}>
                    <div></div>
                    {!isValidBccEmail && bccMail !== "" && (
                      <span className={"mb-2 text-sm font-bold text-error"}>
                        Invalid email address format
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={"px-4 pb-4"}>
            <div className={"grid grid-cols-[1fr_4fr] items-center"}>
              <label className={"label"}>
                <span className={"label-text"}>Subject:</span>
              </label>
              <input
                type={"text"}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className={"input input-bordered h-[40px] w-full"}
              />
            </div>
          </div>
        </div>
        {/* <div>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>*/}
        <div className={"custom-quill-container border-b border-gray-300"}>
          <ReactQuill
            theme="snow"
            value={message}
            onChange={(message) => setMessage(message)}
            placeholder={"Write your message..."}
            className="custom-quill"
          />
          {(isReply || isReplyAll) && (
            <div className={"ml-4 items-end py-2"}>
              <div className={"ml-[-4px] text-sm font-semibold"}>
                {moment
                  .unix(email?.date || 0)
                  .format("On ddd, MMM DD, YYYY аt hh:mm A")}
              </div>
              <div className="border-l-[1px] border-[rgb(204,204,204)]">
                <div
                  dangerouslySetInnerHTML={{
                    __html: email?.body || "",
                  }}
                  className={"w-full px-2 text-sm font-semibold"}
                />
              </div>
            </div>
          )}
        </div>

        <div className={"flex gap-2 px-4 pt-2"}>
          {files.map((file) => (
            <div
              key={file.id}
              className={
                "flex items-center justify-between gap-2 rounded-[10px] border border-[#7c66f0] px-2 py-1"
              }>
              <FaFile color={"#7c66f0"} />
              <div className={"font-semibold"}>{file.filename}</div>
              <HiXMark
                onClick={() =>
                  setFiles((prev) => prev.filter((item) => file.id !== item.id))
                }
                className={"cursor-pointer text-gray-400"}
              />
            </div>
          ))}
        </div>
        <div className={"flex h-[64px] items-center justify-between px-4 py-4"}>
          <Button
            label={"Send"}
            buttonType={"button"}
            onClick={onSubmit}
            isLoading={isLoading || isLoadingCreate}
            disabled={
              isLoading ||
              isLoadingCreate ||
              !(
                selectedEmails?.length ||
                selectedCcEmails?.length ||
                selectedBccEmails?.length
              )
            }
          />
          <div className={"flex items-center gap-4 "}>
            <div className={"cursor-pointer items-center"}>
              <InputFile
                setFile={handleFileChange}
                setError={setError}
                inputRef={addFileRef}
                isLoading={false}
                isEmailPage
              />
            </div>
            <img
              src={"/img/trash.png"}
              alt={"delete"}
              onClick={() => onClose()}
              className={"mt-0.5 w-[20px] cursor-pointer items-center"}
            />
          </div>
          {error && (
            <div className={"text-sm font-semibold text-red-500"}>{error}</div>
          )}
        </div>
      </div>
    </div>
  );
};
