import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Xstate from "@xstate/react";
import React, { useEffect } from "react";
import {
  HiArrowsRightLeft,
  HiOutlineCog6Tooth,
  HiOutlineUserCircle,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import * as devConfig from "../../app/configs/devConfig";
import { LABELS, PATHS, ROUTE_NAMES } from "../../app/constants/TextConstants";
import { getAuthHeader } from "../../app/globalState/GlobalAuthState";
import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import { AvatarPlaceholder } from "../../components/avatar/AvatarPlaceholder";
import Button from "../../components/button/Button";
import { LogoLink } from "../../components/logos/LogoLink";
import { SignOutSvg } from "../../components/svg/SignOutSvg";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  getUser,
  setIsLinkedFirm,
  setNotificationCounts,
} from "../../services/AppService";
import { elapsedTimer } from "../../services/TimerService";
import {
  deleteLinkedFirm,
  getLinkedFirm,
} from "../../sessionStorage/sessionStorage";
import HeaderSearch from "./Search/HeaderSearch";
import Timer from "./Timer";
import { useGetCountsNotificationsQuery } from "../../slices/UserSlice";

function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}

export const MainTopNav: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user, notificationCounts } = useAppSelector(
    (state) => state.appReducer,
  );

  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;

  const { data } = useGetCountsNotificationsQuery({
    userId: user?.id,
    orgId: getLinkedFirm()?.orgId || "",
    isRead: false,
  });

  const handleDeleteLinkFirm = async () => {
    const selectedFirm = getLinkedFirm();
    if (selectedFirm) {
      const response = await fetch(
        `${devConfig.getServerBaseUrlForEnv()}/api/user-organization/remove-link`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getAuthHeader().Authorization,
          },
          method: "DELETE",
          body: JSON.stringify({ userId: user?.id, orgId: selectedFirm.id }),
        },
      );
      if (response.ok) {
        deleteLinkedFirm();
      } else {
      }
    }
  };

  useEffect(() => {
    dispatch(
      getUser({
        userId: userInfoByEmail?.id || "",
        orgId: getLinkedFirm()?.orgId,
      }),
    );
  }, []);

  useEffect(() => {
    if (user?.id) {
      dispatch(
        elapsedTimer({
          userId: user.id,
          orgId: getLinkedFirm()?.orgId,
        }),
      );
    }
  }, [user, getLinkedFirm()]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && user?.id) {
        dispatch(
          elapsedTimer({
            userId: user.id,
            orgId: getLinkedFirm()?.orgId,
          }),
        );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [user]);

  useEffect(() => {
    if (data) {
      dispatch(setNotificationCounts(data?.allCounts));
    }
  }, [data]);

  const ShowNotifications = () => {
    return notificationCounts || 0 ? (
      <div
        className={
          "absolute right-0 top-0 flex h-5 w-5 items-center justify-center rounded-full bg-red-500 !font-notoSans text-[10px] text-white"
        }>
        {notificationCounts > 99 ? "99+" : notificationCounts}
      </div>
    ) : null;
  };
  return (
    <>
      <div className={"z-header bg-purple navbar fixed top-0 h-[64px]"}>
        <div className={"inset-x-0 block flex-1 border-base-200 bg-base-100"}>
          <div className={"bg-purple mx-auto max-w-none space-x-1"}>
            <div className={"flex flex-none text-[21px] font-bold text-white"}>
              <LogoLink />
            </div>
          </div>
        </div>
        <div className={"flex"}>
          <div
            onClick={() => navigate("/notifications")}
            className={
              "relative mr-2 flex w-[43px] cursor-pointer items-center justify-center rounded-full p-2 !font-notoSans text-[24px] text-white hover:bg-purple-700"
            }>
            <FontAwesomeIcon icon={faBell} />
            <ShowNotifications />
          </div>
          <Timer />
          <div className={"mr-4 h-10 w-[300px] "}>
            <HeaderSearch />
          </div>
          <div className={"dropdown dropdown-end"}>
            <label tabIndex={0} className={"avatar btn btn-circle btn-ghost"}>
              <div className={"w-10 rounded-full"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  type={"custom"}
                  avatarCustomColor={user?.userProfile?.color}
                  label={
                    userInfoByEmail?.userProfile?.avatarInitials ??
                    LABELS.unknownUser.defaultInitials
                  }
                />
              </div>
            </label>
            <ul
              tabIndex={0}
              className={
                "dropdown-content menu rounded-box menu-compact mt-3 w-64 bg-base-100 p-2 shadow"
              }>
              <li>
                <div onClick={() => navigate(PATHS.user())}>
                  <div className={"flex items-center gap-2"}>
                    <div>
                      <HiOutlineUserCircle className={"h-4 w-4"} />
                    </div>
                    <div>Profile</div>
                  </div>
                </div>
              </li>
              {!user?.userRoleEnum?.userRole?.includes("Staff") && (
                <li>
                  <div onClick={() => navigate(PATHS.settings())}>
                    <div className={"flex items-center gap-2"}>
                      <div>
                        <HiOutlineCog6Tooth className={"h-4 w-4"} />
                      </div>
                      <div>Settings</div>
                    </div>
                  </div>
                </li>
              )}
              <li>
                <Button
                  colorType={"ghost"}
                  icon={<HiArrowsRightLeft className={"h-4 w-4"} />}
                  label={"Switch firm"}
                  onClick={() => {
                    handleDeleteLinkFirm().then(() => {
                      deleteLinkedFirm();
                      dispatch(setIsLinkedFirm(false));
                    });
                  }}
                  position={"start"}
                  size={"small"}
                  extraClasses={
                    "normal-case font-normal w-full py-2 h-fit min-h-fit"
                  }
                />
              </li>
              <li>
                <Button
                  colorType={"ghost"}
                  label={LABELS.features.signout}
                  icon={<SignOutSvg sizeCx={"h-4 w-4"} />}
                  onClick={() => {
                    handleDeleteLinkFirm().then(() => {
                      sessionStorage.clear();
                      clearAllCookies();
                      navigate(ROUTE_NAMES.signOut);
                      dispatch(setIsLinkedFirm(false));
                    });
                  }}
                  position={"start"}
                  size={"small"}
                  extraClasses={
                    "normal-case font-normal w-full py-2 h-fit min-h-fit"
                  }
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
