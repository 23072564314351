import React, { FC, useState } from "react";
import { WorkDetailsModel } from "../../../../factories/work/model/work-details.model";
import { WorkDetails } from "./widgets/work-details/WorkDetails";
import { WorkFiles } from "./widgets/work-files/WorkFiles";
import { WorkTasks } from "./widgets/work-tasks/WorkTasks";
import { WorkNotes } from "./widgets/work-notes/WorkNotes";
import Button from "../../../../components/button/Button";

type Props = {
  work: WorkDetailsModel;
};

export const WorkDetailsView: FC<Props> = ({ work }) => {
  const [isOpenMessage, setIsOpenMessage] = useState(false);

  return (
    <div className={"relative"}>
      <div className={"grid grid-cols-[1fr_2fr_1fr] gap-6"}>
        <div>
          <WorkDetails work={work} setIsOpenMessage={setIsOpenMessage} />
        </div>
        <div>
          <WorkTasks work={work} />
        </div>
        <div className={"flex flex-col gap-6"}>
          <div>
            <WorkNotes />
          </div>
          <div>
            <WorkFiles />
          </div>
        </div>
      </div>
      {isOpenMessage && (
        <div
          className={
            "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-gray-500/20"
          }>
          <div
            className={"h-[310px] w-[600px] rounded-md bg-white p-6 shadow-md"}>
            <div
              className={"text-bg mb-4 flex w-full justify-center font-bold"}>
              Reccuring work
            </div>
            <div className={"font-semibold text-gray-500"}>
              You are creating a new work item. It can take up to 5 minutes to
              <br />
              see the newly created work items. <br /> You can see all the
              repeated work items in the
              <br /> <b>"Repeats"</b> tab above the page.
              <br />
              <br />
              <br />
            </div>
            <div className={"mt-[10px] flex w-full justify-end gap-4"}>
              <Button
                label={"Cancel"}
                colorType={"outline"}
                onClick={() => {
                  setIsOpenMessage(false);
                }}
              />
              <Button
                label={"Ok"}
                onClick={() => {
                  setIsOpenMessage(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
