import * as signalR from "@microsoft/signalr";
import { useCallback, useEffect, useState } from "react";

import { useAppDispatch } from "../redux/redux";
import { setNotificationCounts } from "../services/AppService";
import { setTimerDuration } from "../services/TimerService";
const useSignalR = (url: string, token: string) => {
  const dispatch = useAppDispatch();
  const [connection, setConnection] = useState<signalR.HubConnection | null>(
    null,
  );
  const [message, setMessage] = useState<unknown | null>(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const connect = async () => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      connection.onreconnecting((error) => {
        console.log("Connection lost. Reconnecting.", error);
        setError("Connection lost.");
      });

      connection.onreconnected((connectionId) => {
        console.log(
          "Connection reestablished. Connected with connectionId",
          connectionId,
        );
        setError("");
      });
      connection.onclose((error) => {
        console.log("Connection closed. Try refreshing the page.", error);
        setError("Connection closed. Try refreshing the page.");
      });

      connection.on("ReceiveNotification", (message) => {
        console.log("on setMessage", message);
        setMessage(message || null);
      });

      //TODO Add another state for timer message
      connection.on("TimerStarted", (message) => {
        console.log("TimerStarted setMessage", message);
        setMessage(message || null);
      });

      connection.on("TimerPaused", (message) => {
        console.log("TimerPaused setMessage", message);
        setMessage(message || null);
      });

      connection.on("TimerEnded", (message) => {
        console.log("TimerEnded setMessage", message);
        setMessage(message || null);
      });
      connection.on("ElapsedTime", (message) => {
        console.log("ElapsedTime setMessage", message);
        dispatch(setTimerDuration(message));
        setMessage(message || null);
      });
      connection.on("Notifications", (message) => {
        console.log("Notifications", message);
        dispatch(setNotificationCounts(message));
        setMessage(message || null);
      });

      try {
        await connection.start();
        console.log("SignalR Connected.");
        setConnection(connection);
      } catch (err) {
        console.log("Error establishing connection", err);
        setError("Error establishing connection");
      }
    };

    void connect();

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [url, token]);

  const sendMessage = useCallback(
    (message) => {
      if (connection) {
        connection
          .invoke("SendMessage", message)
          .catch((err) => console.error(err));
      }
    },
    [connection],
  );

  return { message, sendMessage, error };
};

export default useSignalR;
