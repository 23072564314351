import { ContactView } from "../../../features/contacts/pages/contact/ContactView";
import { ContactNotes } from "../../../features/contacts/pages/contact/pages/contact-notes/ContactNotes";
import { ContactProfile } from "../../../features/contacts/pages/contact/pages/contact-profile/ContactProfile";
import { ContactWorks } from "../../../features/contacts/pages/contact/pages/contact-works/ContactWorks";
import { PagesEnum } from "./types";
import { ContactBilling } from "../../../features/contacts/pages/contact/pages/contact-billing/ContactBilling";
import { ContactEmails } from "../../../features/contacts/pages/contact/pages/contact-emails/ContactEmails";
import { ContactTime } from "../../../features/contacts/pages/contact/pages/contact-time/ContactTime";

export enum ContactPagesEnum {
  Profile = "profile",
  Works = "works",
  Time = "time",
  Notes = "notes",
  Billing = "billing",
  Emails = "emails",
}

export const ContactPages = {
  path: PagesEnum.Contact,
  label: "Contact",
  params: "customerId",
  component: ContactView,
  children: {
    profile: {
      path: ContactPagesEnum.Profile,
      label: "Profile",
      component: ContactProfile,
    },
    works: {
      path: ContactPagesEnum.Works,
      label: "Work",
      component: ContactWorks,
    },
    time: {
      path: ContactPagesEnum.Time,
      label: "Time",
      component: ContactTime,
    },
    notes: {
      path: ContactPagesEnum.Notes,
      label: "Notes",
      component: ContactNotes,
    },
    billing: {
      path: ContactPagesEnum.Billing,
      label: "Billing",
      component: ContactBilling,
    },
    emails: {
      path: ContactPagesEnum.Emails,
      label: "Email",
      component: ContactEmails,
    },
  },
};
