import { Dialog, Transition } from "@headlessui/react";
import moment from "moment/moment";
import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DATE_FORMATS, DATES } from "../../app/commonOps/CommonDateOps";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import {
  deleteTime,
  getAllTimes,
  getTimesByWork,
} from "../../services/AppService";
import {
  endTimer,
  setTimer,
  setTimerDuration,
  startTimer,
} from "../../services/TimerService";
import { OutlineButton } from "../../components/button/OutlineButton";
import Button from "../../components/button/Button";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import InputTime from "../../components/inputs/inputTime";
import { useParams } from "react-router-dom";
import { useLazyGetTimesByWorkQuery } from "../../slices/WorkSlice";
import { DatePicker } from "baseui/datepicker";
import { transformDateToUTC } from "../../utils/transformDate";

interface IAddTimerProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AddTimer: React.FC<IAddTimerProps> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();

  const { timer, duration } = useAppSelector((state) => state.timerReducer);

  const [getTime] = useLazyGetTimesByWorkQuery();

  const [time, setTime] = useState({
    h: (moment.duration(duration, "seconds").hours() || 0).toString(),
    m: (moment.duration(duration, "seconds").minutes() || 0).toString(),
  });

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      date: DATES.today(),
      notes: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await dispatch(
        endTimer({
          id: timer?.id || "",
          date: moment(data.date).utc(),
          duration: Number(time.h) * 3600 + Number(time.m) * 60,
          workId: timer?.workId || "",
          notes: timer?.notes || "",
          assignedUserId: timer?.assignedUserId || null,
          workTypeId: timer?.workTypeId || null,
        }),
      );
      dispatch(setTimer(null));
      dispatch(setTimerDuration(0));
    } catch (e) {}
    dispatch(getAllTimes({ id: getLinkedFirm()?.orgId, userId: user.id }));
    dispatch(
      getTimesByWork({
        jobId: `${timer?.workId}`,
        orgId: `${getLinkedFirm()?.orgId}`,
      }),
    );
    if (workId) {
      getTime({ workId, orgId: getLinkedFirm()?.orgId || "" });
    }
    closeModal();
  };
  useEffect(() => {
    if (isOpen) {
      setTime({
        h: (moment.duration(duration, "seconds").hours() || 0).toString(),
        m: (moment.duration(duration, "seconds").minutes() || 0).toString(),
      });
    }
  }, [isOpen]);

  return (
    <div
      onClick={closeModal}
      id={"edit-time"}
      className={
        "fixed left-0 top-0 z-[4] flex h-screen w-screen items-center justify-center bg-gray-500/20"
      }>
      <div
        onClick={(event) => event.stopPropagation()}
        className={
          "max-h-[750px] w-[480px] overflow-hidden rounded-[10px] bg-white "
        }>
        <div
          className={"max-h-[750px] w-full overflow-auto px-[20px] py-[15px]"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={"w-full text-center"}>Confirm Time</div>

              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Contact</div>
                <input
                  className={
                    "input input-bordered flex w-full items-center disabled:border-[#D4D6D9] disabled:bg-transparent"
                  }
                  value={
                    timer?.businessContact?.companyName
                      ? timer?.businessContact?.companyName
                      : timer?.contact?.firstName +
                        " " +
                        timer?.contact?.lastName
                  }
                  onChange={() => null}
                />
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Work</div>
                <div
                  className={
                    "focus: input input-bordered flex w-full items-center"
                  }>
                  {timer?.work.name}
                </div>
              </div>
              <div className={"mb-4 flex justify-between space-x-[20px]"}>
                <div className={"w-full"}>
                  <div className={"label-text mb-2"}>Date</div>
                  <div>
                    <Controller
                      control={control}
                      name={"date"}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          overrides={{
                            MonthYearSelectPopover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: { zIndex: 10000 },
                                  },
                                },
                              },
                            },
                            Input: {
                              props: {
                                mountNode: document.getElementById("edit-time"),
                                overrides: {
                                  Input: {
                                    style: () => ({
                                      backgroundColor: "#FFFFFF",
                                    }),
                                  },
                                  Root: {
                                    style: () => ({
                                      borderTopWidth: "1px",
                                      borderRightWidth: "1px",
                                      borderBottomWidth: "1px",
                                      borderLeftWidth: "1px",
                                      borderTopColor: "#D4D6D9",
                                      borderRightColor: "#D4D6D9",
                                      borderBottomColor: "#D4D6D9",
                                      borderLeftColor: "#D4D6D9",
                                    }),
                                  },
                                },
                              },
                            },
                            Popover: {
                              props: {
                                overrides: {
                                  Body: {
                                    style: {
                                      zIndex: 9999,
                                    },
                                  },
                                },
                              },
                            },
                          }}
                          value={value}
                          formatString={DATE_FORMATS.dateOnly}
                          onChange={({ date }) => {
                            if (date instanceof Date) {
                              onChange(transformDateToUTC(date));
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={"w-full"}>
                  <div className={"label-text mb-2"}>Duration</div>
                  <div>
                    <InputTime value={time} onChange={setTime} />
                  </div>
                </div>
              </div>
              <div className={"mb-4"}>
                <div className={"label-text mb-2"}>Notes</div>
                <div>
                  <textarea
                    autoFocus={true}
                    {...register("notes")}
                    className={
                      "input input-bordered min-h-[100px] w-full py-[8px]"
                    }
                  />
                </div>
              </div>
              <div className={"flex justify-between"}>
                <Button
                  onClick={() => {
                    dispatch(setTimer(null));
                    dispatch(setTimerDuration(0));
                    if (timer?.id) {
                      dispatch(deleteTime(timer));
                    }
                    closeModal();
                  }}
                  buttonType={"button"}
                  colorType={"error"}
                  label={"Delete"}
                  extraClasses={"normal-case"}
                />
                <div className={"flex space-x-[10px]"}>
                  <OutlineButton
                    colorType={"neutral"}
                    label={"Cancel"}
                    onClick={() => {
                      dispatch(
                        startTimer({
                          workId: timer?.workId || "",
                          customerId:
                            timer?.contactId || timer?.businessContactId || "",
                        }),
                      );
                      closeModal();
                    }}
                    extraClasses={"normal-case"}
                  />
                  <Button
                    buttonType={"submit"}
                    label={"Save"}
                    extraClasses={"normal-case"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTimer;
