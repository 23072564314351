import React, { FC, useEffect, useState } from "react";
import { HiMiniAdjustmentsHorizontal } from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import {
  useArchiveOrUnarchiveMutation,
  useExportWorksQuery,
  useGetAllWorkStatsQuery,
  useGetWorksQuery,
} from "../../../slices/WorkSlice";
import { SelectOptionType } from "../../../types/types";
import { AllWorksFilters } from "../components/AllWorksFilters";
import { WorkTableSort } from "../components/types/work-table";
import { WorkList } from "../components/WorkList";
import { WorksStats } from "../components/WorksStats";
import { AddWorkModal } from "../modals/AddWork.modal";
import { FilterWorksModal } from "../modals/FilterWorks.modal";
import { ExcelExport } from "../../../utils/ExportData";
import moment from "moment";
import { useAppSelector } from "../../../redux/redux";
import { WorkModel } from "../../../factories/works/models/work.model";
import { BsArchive } from "react-icons/bs";

export type Filters = {
  customerIds: SelectOptionType[];
  workName: SelectOptionType[];
  userIds: SelectOptionType[];
  workStatuses: SelectOptionType[];
  worktypeIds: SelectOptionType[];

  startDates: [] | ["BetweenDates"];
  dueDates: [] | ["BetweenDates"];
  fromDate: Date | null;
  toDate: Date | null;
};

export const AllWorks: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAppSelector((state) => state.appReducer);

  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const [archiveWorks, setArchiveWorks] = useState<WorkModel[]>([]);

  const [activateDeactivate] = useArchiveOrUnarchiveMutation();

  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });

  const {
    data: workStats,
    isLoading,
    isError,
  } = useGetAllWorkStatsQuery({ orgId: getLinkedFirm()?.orgId || "" });

  const { data: works, isLoading: isLoadingWorks } = useGetWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: filters.customerIds.map((item) => item.value) || [],
    workName: filters.workName.map((item) => item.label) || [],
    userIds: filters.userIds.map((item) => item.value) || [],
    workStatuses: filters.workStatuses.map((i) => i.label) || [],
    worktypeIds: filters.worktypeIds.map((item) => item.value) || [],
    startDates: filters.startDates,
    dueDates: filters.dueDates,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    currentUserId: user.id,
  });

  const { data: allWorks } = useExportWorksQuery({
    pageNumber: page,
    pageSize: 20,
    orgId: getLinkedFirm()?.orgId || "",
    sortBy: sortBy,
    sortOrder: sortOrder,

    customerIds: filters.customerIds.map((item) => item.value) || [],
    workName: filters.workName.map((item) => item.label) || [],
    userIds: filters.userIds.map((item) => item.value) || [],
    workStatuses: filters.workStatuses.map((i) => i.label) || [],
    worktypeIds: filters.worktypeIds.map((item) => item.value) || [],
    startDates: filters.startDates,
    dueDates: filters.dueDates,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
    currentUserId: user.id,
  });

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    setSearchParams({
      page: page?.toString() || "1",
      sortBy: sortBy?.toString() || WorkTableSort.StartDate,
      sortOrder: sortOrder?.toString() || "desc",
    });
  }, []);

  if (isLoading) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6 "}>
        <WorksStats
          allWorks={workStats?.allWorksCount || 0}
          completedWorks={workStats?.completedWorkCount || 0}
        />
        <WorkList
          works={works || []}
          statusFilter={filters.workStatuses.map((i) => i.label)}
          isLoading={isLoadingWorks}
          hasPages
          setArchiveWorks={setArchiveWorks}
          archiveWorks={archiveWorks}>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6 xl:items-end"
            }>
            <AllWorksFilters setFilters={setFilters} filters={filters} />
            <div
              className={
                "grid grid-cols-[repeat(3,max-content)] gap-2 pt-6 xl:pt-0"
              }>
              <div className={"flex items-center gap-6"}>
                <div
                  aria-disabled={!!archiveWorks.length}
                  onClick={() => {
                    activateDeactivate({
                      body: archiveWorks.map((i) => i.workId),
                      archiveWork: true,
                    });
                  }}>
                  <BsArchive
                    data-tooltip-id="tooltip-archive"
                    color={"#7c66f0"}
                    size={"20px"}
                    className={
                      "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                    }
                  />
                </div>
                <ExcelExport
                  data={
                    allWorks?.map((work) => ({
                      "Work Name": work.workName,
                      Contact: work.customerName,
                      Status: work.jobStatus,
                      "Start Date": moment(work.startDate).format("YYYY-MM-DD"),
                      "Due Date": moment(work.dueDate).format("YYYY-MM-DD"),
                      Progress:
                        (work.completedTaskCounts / work.taskCounts) * 100 || 0,
                      Repeats: work.repeats,
                      "Work Type": work.workType,
                      Assignee: work.userFirstName + " " + work.userLastName,
                    })) || []
                  }
                  fileName={"All Works"}
                />
              </div>

              <Button
                label={""}
                icon={
                  <HiMiniAdjustmentsHorizontal
                    size={24}
                    className={"text-purple"}
                  />
                }
                onClick={() => setIsOpenFilters(true)}
                colorType={"ghost"}
              />
              <Button
                buttonType={"button"}
                label={"Add work"}
                onClick={() => setIsAddWork(true)}
              />
            </div>
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      {isOpenFilters && (
        <FilterWorksModal
          isOpen={isOpenFilters}
          onClose={() => setIsOpenFilters(false)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </>
  );
};
