import React, { useState } from "react";
import { match } from "ts-pattern";
import classNames from "classnames";

type Props = {
  size: "2xs" | "extra-small" | "small" | "medium" | "large" | "super-small";
  label: string;
  type?: "purple" | "blue" | "custom";
  username?: string;
  isShowUsername?: boolean;
  avatarCustomColor?: string;
};

export const AvatarPlaceholder: React.FC<Props> = ({
  type = "blue",
  size,
  label,
  username,
  isShowUsername = false,
  avatarCustomColor,
}) => {
  const [isOpenUsername, setIsOpenUsername] = useState(false);

  const { avatarSizeCx, textSizeCx } = match(size)
    .with("large", () => {
      return {
        avatarSizeCx: "w-32 h-32",
        textSizeCx: "text-3xl",
      };
    })
    .with("medium", () => {
      return { avatarSizeCx: "w-24 h-24", textSizeCx: "text-xl" };
    })
    .with("small", () => {
      return {
        avatarSizeCx: "w-16 h-16",
        textSizeCx: "text-base",
      };
    })
    .with("extra-small", () => {
      return { avatarSizeCx: "w-10 h-10", textSizeCx: "text-sm" };
    })
    .with("super-small", () => {
      return { avatarSizeCx: "w-8 h-8", textSizeCx: "text-xs" };
    })
    .with("2xs", () => {
      return { avatarSizeCx: "w-6 h-6", textSizeCx: "text-2xs" };
    })
    .exhaustive();
  const avatarType = match(type)
    .with("blue", () => {
      return { backgroundColor: "rgb(27,62,159)" };
    })
    .with("purple", () => {
      return { backgroundColor: "rgb(134,15,134)" };
    })
    .with("custom", () => {
      return avatarCustomColor
        ? { backgroundColor: avatarCustomColor }
        : { backgroundColor: "rgb(30, 58, 138)" };
    })
    .exhaustive();

  const { usernamePosition, textUsernameSize } = match(size)
    .with("large", () => {
      return {
        usernamePosition: "top-[132px]",
        textUsernameSize: "text-3xl",
      };
    })
    .with("medium", () => {
      return {
        usernamePosition: "top-[68px]",
        textUsernameSize: "text-xl",
      };
    })
    .with("small", () => {
      return {
        usernamePosition: "top-[52px]",
        textUsernameSize: "text-base",
      };
    })
    .with("extra-small", () => {
      return {
        usernamePosition: "top-[44px]",
        textUsernameSize: "text-sm",
      };
    })
    .with("super-small", () => {
      return {
        usernamePosition: "top-[36px]",
        textUsernameSize: "text-xs",
      };
    })
    .with("2xs", () => {
      return {
        usernamePosition: "top-[28px]",
        textUsernameSize: "text-xs",
      };
    })
    .exhaustive();

  return (
    <>
      <div className={"avatar placeholder relative"}>
        <div
          onMouseEnter={() => setIsOpenUsername(true)}
          onMouseLeave={() => setIsOpenUsername(false)}
          className={classNames(
            "relative rounded-full text-neutral-content",
            avatarType,
            avatarSizeCx,
          )}
          style={avatarType}>
          <span className={`!font-notoSans uppercase ${textSizeCx}`}>
            {label}
          </span>
        </div>
        {isShowUsername && isOpenUsername && (
          <div
            style={{ aspectRatio: 0 }}
            className={`absolute ${usernamePosition} left-0  z-[1] whitespace-nowrap rounded-[4px] bg-gray-500 px-3 py-1.5 !font-notoSans text-xs font-semibold text-white`}>
            {username}
          </div>
        )}
      </div>
    </>
  );
};
