import React, { FC, useEffect, useState } from "react";
import Button from "../../../../components/button/Button";
import { WorkList } from "../../../works/components/WorkList";
import {
  useArchiveOrUnarchiveMutation,
  useGetRepeatsWorkQuery,
} from "../../../../slices/WorkSlice";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import { useParams, useSearchParams } from "react-router-dom";
import { AddWorkModal } from "../../../works/modals/AddWork.modal";
import { FilterWorksModal } from "../../../works/modals/FilterWorks.modal";
import { Filters } from "../../../works/pages/AllWorks";
import { useAppSelector } from "../../../../redux/redux";
import { WorkTableSort } from "../../../works/components/types/work-table";
import { BsArchive } from "react-icons/bs";
import { WorkModel } from "../../../../factories/works/models/work.model";

export const WorkRepeats: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();

  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";
  const sortBy = searchParams.get("sortBy") as WorkTableSort;

  const [isAddWork, setIsAddWork] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [archiveWorks, setArchiveWorks] = useState<WorkModel[]>([]);
  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });

  const { data: works, isFetching: isLoadingWorks } = useGetRepeatsWorkQuery({
    orgId: getLinkedFirm()?.orgId || "",
    workId: workId || "",
    currentUserId: user.id,
    sortBy: sortBy,
    sortOrder: sortOrder,
  });
  const [activateDeactivate] = useArchiveOrUnarchiveMutation();

  useEffect(() => {
    setSearchParams({
      sortBy: sortBy?.toString() || WorkTableSort.StartDate,
      sortOrder: sortOrder?.toString() || "desc",
    });
  }, []);

  return (
    <div>
      <WorkList
        works={works || []}
        isLoading={isLoadingWorks}
        archiveWorks={archiveWorks}
        setArchiveWorks={setArchiveWorks}>
        <div
          className={
            "flex w-full items-center justify-between px-[32px] py-[24px]  font-bold"
          }>
          <div>All Work ({works?.[0]?.totalRecords || 0})</div>
          <div className={"flex items-center gap-4"}>
            <div
              aria-disabled={!!archiveWorks.length}
              onClick={() => {
                activateDeactivate({
                  body: archiveWorks.map((i) => i.workId),
                  archiveWork: true,
                });
              }}>
              <BsArchive
                data-tooltip-id="tooltip-archive"
                color={"#7c66f0"}
                size={"20px"}
                className={
                  "flex cursor-pointer items-center focus:outline-0 active:outline-0"
                }
              />
            </div>
            <Button
              buttonType={"button"}
              label={"Add work"}
              onClick={() => setIsAddWork(true)}
            />
          </div>
        </div>
      </WorkList>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
      {isOpenFilters && (
        <FilterWorksModal
          isOpen={isOpenFilters}
          onClose={() => setIsOpenFilters(false)}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </div>
  );
};
