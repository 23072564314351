import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import CustomSelect from "../../../components/select/Select";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import { useAppSelector } from "../../../redux/redux";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { useGetUsersQuery } from "../../../slices/UserSlice";
import { useGetWorkTypesQuery } from "../../../slices/WorkTypeSlice";
import { repeatNever, SelectOptionType } from "../../../types/types";
import { useGetTemplatesQuery } from "../../../slices/TemplatesSlice";
import { StyledDatePicker } from "../../../components/datepicker/datepicker";
import moment from "moment";
import Button from "../../../components/button/Button";
import { useAddWorkMutation } from "../../../slices/WorkSlice";
import { useNavigate } from "react-router-dom";
import { AddWorkDto } from "../../../factories/works/dtos/add-work.dto";
import { useDebounce } from "usehooks-ts";
import AddContact from "../../../components/modal/addContact";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { Email } from "../../../factories/emails/models/emails.models";
import { useAddEmailToWorkMutation } from "../../../slices/EmailsSlice";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  defaultContact?: SelectOptionType;
  selectedMail?: Email | null;
};

type FormData = {
  workName: string;
  workType: SelectOptionType | null;
  subtaskTemplate: SelectOptionType | null;
  contact: SelectOptionType | null;
  assignedTo: SelectOptionType | null;
  startDate: string;
  dueDate: string;
};

export const AddWorkModal: FC<Props> = ({
  isOpen,
  onClose,
  defaultContact,
  selectedMail,
}) => {
  const navigate = useNavigate();

  const [addWork, { isSuccess, data: newWork }] = useAddWorkMutation();

  const { user } = useAppSelector((state) => state.appReducer);

  const [searchContacts, setSearchContacts] = useState("");
  const [isOpenAddContact, setIsOpenAddContact] = useState(false);

  const searchContactsDebounce = useDebounce(searchContacts, 300);

  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: searchContactsDebounce || "a",
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });
  const { data: templates, isLoading: isLoadingTemplates } =
    useGetTemplatesQuery({ orgId: getLinkedFirm()?.orgId || "" });
  const [addWorkToEmail, { isSuccess: isSuccessAdd }] =
    useAddEmailToWorkMutation();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      startDate: moment().toISOString(),
      dueDate: moment().toISOString(),
      contact: defaultContact || null,
      assignedTo: {
        label: `${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`,
        value: user.id,
        avatar: getInitials(
          user?.userProfile?.firstName,
          user?.userProfile?.lastName,
        ),
      },
    },
  });

  const contactsOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };

  const usersOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (users) {
      users?.forEach((item) => {
        options.push({
          avatar: getInitialsFromFullName(
            `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          ),
          label: `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          value: `${item?.id}`,
        });
      });
    }
    return options;
  };

  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
        });
      });
    }
    return options;
  };

  const templateOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (templates) {
      templates?.forEach((item) => {
        options.push({
          value: item?.id,
          label: item?.templateName,
        });
      });
    }
    return options;
  };

  const onSubmit = (data: FormData) => {
    const body: AddWorkDto = {
      name: data?.workName || "",
      orgId: null,
      orgRelayId: getLinkedFirm()?.orgId || "",
      serviceId: data?.workType?.value || "",
      templateId: data?.subtaskTemplate ? data?.subtaskTemplate?.value : null,
      repeatTypeRecurring: 0,
      dueDate: moment(data.dueDate).utc(),
      startDate: moment(data.startDate).utc(),
      assignedUserId: data.assignedTo?.value || "",
      customerId: data?.contact?.value || "",
      workTasks: [],
      assignedByUserId: user.id,
    };
    addWork(body);
  };

  useEffect(() => {
    if (isSuccess && newWork) {
      onClose();
      navigate(`/work/${newWork?.id}`);
      if (selectedMail) {
        addWorkToEmail({
          body: {
            messageId: selectedMail?.id || "",
            threadId: selectedMail?.thread_id || "",
            workId: newWork?.id || "",
          },
        });
      }
    }
  }, [isSuccess, newWork]);

  const navigateToContacts = (contact: SelectOptionType) => {
    navigate(
      navigateToContact({
        type: contact.avatarColor === "purple" ? "organization" : "contact",
        customerId: contact.value || "",
        id: contact.value || "",
      }),
    );
  };

  return (
    <div
      className={classNames(
        "modal !z-[4]",
        isOpen ? "modal-open" : "modal-close",
      )}>
      <div className={"modal-box w-[480px] overflow-hidden p-0"}>
        <div className={"max-h-[calc(100vh-5em)] overflow-y-auto"}>
          <div
            className={
              "relative border-b border-gray-300 px-5 py-4 text-center text-lg font-bold"
            }>
            New work
            <div className={"absolute right-5 top-0 flex h-full items-center"}>
              <img
                src={"/img/closse.png"}
                alt={""}
                onClick={onClose}
                className={"cursor-pointer"}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                "grid-rows-[repeat(6, max-content)] grid gap-4 px-5 pt-7"
              }>
              <div>
                <div className={"label-text mb-2"}>Work Name*</div>
                <div>
                  <input
                    {...register("workName", {
                      validate: (value) =>
                        !value.trim() ? "This field is required" : true,
                      maxLength: {
                        value: 250,
                        message:
                          "Work name shouldn't be more than 250 characters",
                      },
                    })}
                    className={"input input-bordered w-full"}
                  />
                  {errors?.workName?.message && (
                    <div className={"mt-2 text-sm font-bold text-error"}>
                      {errors?.workName?.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={"label-text mb-2"}>Work Type*</div>
                <div>
                  <Controller
                    name={"workType"}
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        options={workTypeOptions()}
                        value={value}
                        onChange={(newValue) => {
                          const newWorkType = newValue as SelectOptionType;
                          onChange(newWorkType);
                        }}
                        fullWidth
                        isLoading={isLoadingWorkTypes}
                      />
                    )}
                  />
                  {errors?.workType?.message && (
                    <div className={"mt-2 text-sm font-bold text-error"}>
                      {errors?.workType?.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={"label-text mb-2"}>Subtask Template</div>
                <div>
                  <Controller
                    name={"subtaskTemplate"}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        fullWidth
                        options={templateOptions()}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue as SelectOptionType);
                        }}
                        isLoading={isLoadingTemplates}
                      />
                    )}
                  />
                  {errors?.subtaskTemplate?.message && (
                    <div className={"mt-2 text-sm font-bold text-error"}>
                      {errors?.subtaskTemplate?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={"mt-6"}>
                <div className={"label-text mb-2"}>Contact*</div>
                <div>
                  <Controller
                    name={"contact"}
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        options={contactsOptions()}
                        value={value}
                        onChange={(newValue) => {
                          const newContact = newValue as SelectOptionType;
                          onChange(newContact);
                        }}
                        setIsOpenAddContact={setIsOpenAddContact}
                        inputValue={searchContacts}
                        onChangeInput={setSearchContacts}
                        fullWidth
                        withAvatar
                        isLoading={isLoadingContacts}
                      />
                    )}
                  />
                  {errors?.contact?.message && (
                    <div className={"mt-2 text-sm font-bold text-error"}>
                      {errors?.contact?.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={"label-text mb-2"}>Assigned to*</div>
                <div>
                  <Controller
                    name={"assignedTo"}
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <CustomSelect
                        options={usersOptions()}
                        value={value}
                        onChange={(newValue) => {
                          const newUser = newValue as SelectOptionType;
                          onChange(newUser);
                        }}
                        fullWidth
                        withAvatar
                        isLoading={isLoadingUsers}
                      />
                    )}
                  />
                  {errors?.assignedTo?.message && (
                    <div className={"mt-2 text-sm font-bold text-error"}>
                      {errors?.assignedTo?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className={"grid grid-cols-2 gap-2"}>
                <div>
                  <div className={"label-text mb-2"}>Start Date</div>
                  <div>
                    <Controller
                      name={"startDate"}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <StyledDatePicker
                          value={moment(value).toDate()}
                          onChange={(date) =>
                            onChange(moment(date).toISOString())
                          }
                          maxDate={moment(getValues("dueDate")).toDate()}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <div className={"label-text mb-2"}>Due Date</div>
                  <div>
                    <Controller
                      name={"dueDate"}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <StyledDatePicker
                          value={moment(value).toDate()}
                          onChange={(date) =>
                            onChange(moment(date).toISOString())
                          }
                          minDate={moment(getValues("startDate")).toDate()}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={"flex items-center justify-end gap-4 px-5 pb-8 pt-4"}>
              <Button
                colorType={"outline"}
                label={"Cancel"}
                onClick={onClose}
                buttonType={"button"}
              />
              <Button buttonType={"submit"} label={"Save"} />
            </div>
          </form>
        </div>
      </div>
      {isOpenAddContact && (
        <AddContact
          isIdLikeContactId={false}
          isOpen={isOpenAddContact}
          closeModal={() => setIsOpenAddContact(false)}
          setContactsForSort={(newContact) => {
            const newData: SelectOptionType = {
              label: newContact?.name || "",
              value: newContact?.customerId || "",
              avatar: getInitialsFromFullName(newContact?.name),
              avatarColor: newContact?.isBusinessContact ? "purple" : "blue",
            };
            setValue("contact", newData);
            setIsOpenAddContact(false);
          }}
          isSearch
        />
      )}
    </div>
  );
};
