import { Dispatch, FC, SetStateAction, useState } from "react";

import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { EditWorkDetails } from "./EditWorkDetails";
import { WorkDetailsInfo } from "./WorkDetailsInfo";

type Props = {
  work: WorkDetailsModel;
  setIsOpenMessage: Dispatch<SetStateAction<boolean>>;
};

export const WorkDetails: FC<Props> = ({ work, setIsOpenMessage }) => {
  const [isEdit, setIsEdit] = useState(false);

  return isEdit ? (
    <EditWorkDetails
      work={work}
      setIsEdit={setIsEdit}
      setIsOpenMessage={setIsOpenMessage}
    />
  ) : (
    <WorkDetailsInfo work={work} setIsEdit={setIsEdit} />
  );
};
