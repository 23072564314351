import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import Button from "../../components/button/Button";
import { PageLayout } from "../../components/layouts/PageLayout";
import { useAppSelector } from "../../redux/redux";
import { GetPageTitle } from "../../support/ScrollToTop";
import StartTimer from "./StartTimer";
import { TimesListView } from "./TimesListView";
import { ExcelExport } from "../../utils/ExportData";
import { timeFormatter } from "../../constants/constants";
import moment from "moment";
import {
  useLazyFilterTimeQuery,
  useLazyGetBilledHoursQuery,
} from "../../slices/UserSlice";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { Time } from "./InvoiceTime";
import { TimePeriod } from "../../hooks/useDetectTimePeriod";

export const TimeView: React.FC<unknown> = () => {
  const { timer } = useAppSelector((state) => state.timerReducer);
  const [isOpenStart, setIsOpenStart] = useState(false);

  const [fetchTime, { data: filterTime }] = useLazyFilterTimeQuery();
  const [getBilledHours, { data: billableHours }] =
    useLazyGetBilledHoursQuery();

  const [filter, setFilter] = useState<Time>({
    filterByDate: [TimePeriod.LastYear],
    filterByWorkType: [],
    filterByClient: [],
    filterByAssignee: [],
    filterByStatus: null,
    fromDate: null,
    toDate: null,
    orgId: getLinkedFirm().orgId,
    sortOrder: "desc",
    sortBy: "Date",
  });

  const getData = async () => {
    fetchTime({
      filterByClient: filter.filterByClient.map((item) => item.value),
      filterByAssignee: filter.filterByAssignee.map((item) => item.value),
      filterByDate: filter.filterByDate,
      filterByWorkType: filter.filterByWorkType.map((item) => item.value),
      filterByStatus: filter.filterByStatus,
      fromDate: filter.fromDate?.toDateString(),
      toDate: filter.toDate?.toDateString(),
      orgId: getLinkedFirm().orgId,
      sortOrder: filter.sortOrder,
      sortBy: filter.sortBy,
    });
    getBilledHours({
      orgId: getLinkedFirm()?.orgId || "",
    });
  };

  useEffectOnce(() => {
    document.title = GetPageTitle("Time");
  });

  const buttonTimerVariant = () => {
    if (timer?.timerStatus === "ended" || timer?.timerStatus === null) {
      return (
        <div className={"flex gap-2"}>
          <div className={"flex items-center"}>
            <ExcelExport
              data={
                filterTime?.map((time) => ({
                  Work: time.work?.name,
                  Contact: time.contactId
                    ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                    : time?.businessContact?.companyName,
                  "Work Type": time.work?.service?.name,
                  "Total time": timeFormatter(time.duration),
                  Date: moment(time.date).format("YYYY-MM-DD"),
                  Assignee: `${time.assignedUser.userProfile.firstName} ${time.assignedUser.userProfile.lastName}`,
                  Notes: time.notes || "",
                })) || []
              }
              fileName={"Time"}
            />
          </div>
          <Button
            onClick={() => setIsOpenStart(true)}
            buttonType={"button"}
            label={"Start timer"}
            extraClasses={"normal-case"}
          />
        </div>
      );
    } else {
      return (
        <div className={"flex gap-2"}>
          <div className={"flex items-center"}>
            <ExcelExport
              data={
                filterTime?.map((time) => ({
                  Work: time.work?.name,
                  Contact: time.contactId
                    ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                    : time?.businessContact?.companyName,
                  "Work Type": time.work?.service?.name,
                  "Total time": timeFormatter(time.duration),
                  Date: moment(time.date).format("YYYY-MM-DD"),
                  Assignee: `${time.assignedUser.userProfile.firstName} ${time.assignedUser.userProfile.lastName}`,
                  Notes: time.notes || "",
                })) || []
              }
              fileName={"Time"}
            />
          </div>
          {/*<Button
            onClick={() => {
              if (timer?.timerStatus === "paused") {
                dispatch(
                  startTimer({
                    workId: timer.workId,
                    customerId: timer.customerId,
                  }),
                );
              } else {
                dispatch(pauseTimer(timer?.id || ""));
              }
            }}
            buttonType={"button"}
            label={timer?.timerStatus === "paused" ? "Continue" : "Pause"}
            extraClasses={"normal-case"}
          />*/}
        </div>
      );
    }
  };
  return (
    <>
      <PageLayout title={"Time"} extraClasses={"overflow-visible"}>
        <TimesListView
          buttonTimerVariant={buttonTimerVariant}
          billableHours={billableHours}
          filterTime={filterTime}
          getData={getData}
          filter={filter}
          setFilter={setFilter}
        />
        <div>
          <Outlet />
        </div>
      </PageLayout>
      {isOpenStart && (
        <StartTimer
          isOpen={isOpenStart}
          closeModal={() => setIsOpenStart(false)}
        />
      )}
    </>
  );
};
