import React, { FC, useEffect, useState } from "react";
import { IOrganizationContact, ITime } from "../../../../../../types/types";
import { timeListHeaderItems } from "../../../../../time/types/time-list";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
  timeFormatter,
} from "../../../../../../constants/constants";
import { navigateToContact } from "../../../../utils/navigateToContacts";
import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";
import { useGetWorksByCustomerIdMutation } from "../../../../../../slices/UserSlice";
type Props = {
  organization: IOrganizationContact;
};
export const OrganizationTime: FC<Props> = ({ organization }) => {
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);

  const [getTime, { data: timeData }] = useGetWorksByCustomerIdMutation();

  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };

  useEffect(() => {
    getTime({ customerId: organization.customerId });
  }, [organization]);

  return (
    <div>
      <table className={"w-full"}>
        <thead className={"border-b border-gray-300"}>
          <tr>
            {timeListHeaderItems.map((item) => (
              <th
                key={item.title}
                className={"p-4 text-start text-sm text-gray-500"}>
                <div
                  className={classNames(
                    "flex items-center gap-2",
                    !item?.hideSort && "cursor-pointer",
                  )}>
                  <div className={"whitespace-nowrap"}>{item.title}</div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={"text-sm"}>
          {timeData?.map((time) => (
            <tr
              key={time?.id}
              className={
                "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
              }>
              <td className={"items-center px-[16px] py-[8px]"}>
                <div className={"flex items-center"}>
                  <div
                    className={"max-w-[300px] items-center whitespace-normal"}>
                    <Link
                      to={`/work/${time?.work?.id}`}
                      className={
                        "link-primary text-sm font-medium hover:underline"
                      }>
                      {time.work?.name}
                    </Link>
                  </div>
                </div>
              </td>
              <td className={"items-center px-[16px] py-[8px]"}>
                <div className={"flex items-center"}>
                  {time.contactId ? (
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"2xs"}
                        label={getInitials(
                          time?.contact?.firstName || "",
                          time?.contact?.lastName || "",
                        )}
                      />
                      <div className={"pl-2 text-[14px] normal-case"}>
                        <Link
                          to={
                            time.contact
                              ? navigateToContact({
                                  type: "contact",
                                  customerId: time?.work?.customerId || "",
                                })
                              : ""
                          }
                          className={
                            "link-primary text-sm font-medium hover:underline"
                          }>
                          {time.contact
                            ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                            : "Contact name"}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className={"flex items-center text-[14px]"}>
                      <AvatarPlaceholder
                        size={"2xs"}
                        type={"purple"}
                        label={getInitialsFromFullName(
                          time?.businessContact?.companyName || "",
                        )}
                      />
                      <div className={"pl-2 text-[14px] normal-case"}>
                        <Link
                          to={
                            time.businessContact
                              ? `/organization/${time.businessContactId}`
                              : ""
                          }
                          className={
                            "link-primary text-sm font-medium hover:underline"
                          }>
                          {time.businessContact
                            ? time.businessContact.companyName
                            : "Contact name"}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </td>
              <td
                className={
                  "items-center px-[16px] py-[8px] text-[14px] font-semibold"
                }>
                {time.work?.service?.name}
              </td>
              <td
                className={
                  "link-primary cursor-pointer items-center px-[16px] py-[8px] text-sm font-medium hover:underline"
                }>
                {timeFormatter(time.duration)}
              </td>
              <td
                className={
                  " min-w-[120px] items-center px-[16px] py-[8px] text-[14px] font-semibold"
                }>
                {moment(time.date).format("YYYY-MM-DD")}
              </td>
              <td className={"px-[16px] py-[8px] text-[14px] font-semibold"}>
                {time?.assignedUser?.hourlyRate &&
                  Math.trunc(
                    time?.assignedUser?.hourlyRate *
                      totalRate(timeFormatter(time.duration)),
                  )}
                $
              </td>
              <td className={"px-[16px] py-[8px] text-[14px] font-semibold"}>
                {time.isBilled ? (
                  <div className={"flex items-center gap-2"}>
                    <FaCheckCircle className={"text-green-500"} /> Billed
                  </div>
                ) : (
                  "Saved"
                )}
              </td>
              <td className={"relative bg-transparent px-4 py-2"}>
                <div className={"flex items-center text-sm"}>
                  <AvatarPlaceholder
                    size={"2xs"}
                    label={getInitials(
                      time.assignedUser?.userProfile?.firstName || "",
                      time.assignedUser?.userProfile?.lastName || "",
                    )}
                  />
                  <div
                    className={
                      "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
                    }>
                    {time.assignedUser &&
                      `${time.assignedUser?.userProfile?.firstName}
              ${time.assignedUser?.userProfile?.middleName} 
              ${time.assignedUser?.userProfile?.lastName}`}
                  </div>
                </div>
              </td>
              <td
                className={"px-[16px] py-[8px] text-[14px] "}
                onClick={() => {
                  setCollapsedItems((prev) =>
                    collapsedItems.includes(time?.id)
                      ? prev.filter((item) => item !== time?.id)
                      : [...prev, time?.id],
                  );
                }}>
                <div
                  className={`${
                    !collapsedItems.includes(time?.id)
                      ? "...  max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] 2xl:max-w-[100px]"
                      : " max-w-[100px] whitespace-pre-line"
                  }`}>
                  {time.notes}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
