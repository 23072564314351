import React, { FC, useEffect, useRef, useState } from "react";
import ReassignedSvg from "../../../../../../components/svg/ReassignedSvg";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import { getInitials } from "../../../../../../constants/constants";
import { useGetUsersQuery } from "../../../../../../slices/UserSlice";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import {
  useArchiveOrUnarchiveMutation,
  useDeleteWorkMutation,
  useUpdateWorkMutation,
} from "../../../../../../slices/WorkSlice";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import ConfirmModal from "../../../../../../components/modal/ConfirmModal";
import EditDots from "../../../../../../components/EditDots/EditDots";
import { useNavigate } from "react-router-dom";
import { BsArchive } from "react-icons/bs";
import { useAppSelector } from "../../../../../../redux/redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

type Props = {
  work: WorkDetailsModel;
};

export const WorkButtons: FC<Props> = ({ work }) => {
  const [isOpenReassign, setIsOpenReassign] = useState(false);
  const { data: users } = useGetUsersQuery(getLinkedFirm()?.orgId);
  const { user } = useAppSelector((state) => state.appReducer);

  const [deleteWork, { isSuccess }] = useDeleteWorkMutation();
  const [updateWork] = useUpdateWorkMutation();
  const [activateDeactivate] = useArchiveOrUnarchiveMutation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isArchived, setIsArchived] = useState(work.isArchived);
  const [isOpenActivation, setIsOpenActivation] = useState(false);

  const usersRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useOnClickOutside(usersRef, () => setIsOpenReassign(false));

  useEffect(() => {
    setIsArchived(work.isArchived);
  }, [work]);

  useEffect(() => {
    if (isSuccess) {
      setConfirmDelete(false);
      navigate(-1);
    } else {
      setConfirmDelete(false);
    }
  }, [isSuccess]);

  return (
    <div className={"flex gap-2"}>
      <div
        className={
          "relative flex w-fit items-center justify-center rounded-[8px] font-normal transition-all duration-200 hover:border-gray-200 hover:bg-gray-200"
        }>
        <EditDots>
          <div>
            <div ref={usersRef} className={"w-[150px]"}>
              <div
                className={
                  "flex cursor-pointer items-center gap-2 rounded-[5px] px-2 py-1 text-center text-base font-normal hover:bg-gray-300"
                }
                onClick={() => setIsOpenReassign(!isOpenReassign)}>
                <div className={"text-purple"}>
                  <ReassignedSvg size={"18px"} />
                </div>
                Reassign
              </div>

              {isOpenReassign && (
                <div
                  className={
                    "absolute right-0 top-8 z-[1] flex max-h-[300px] w-[225px] flex-col space-y-[2px] overflow-hidden rounded-[10px] border-[1px] border-gray-300 bg-white text-[13px]"
                  }>
                  <div
                    className={
                      "link-primary border-b-[1px] border-b-primary px-4 py-2 text-[14px]"
                    }>
                    Reassign work to:
                  </div>
                  <div className={"overflow-y-auto"}>
                    {users?.map((item) => (
                      <div
                        onClick={() => {
                          updateWork({
                            workId: work.id,
                            workName: work.name,
                            serviceId: work.serviceId,
                            taskTemplateId: work.templateId,
                            userId: item.id,
                            customerId: work.customerId,
                            businessContactId: work.businessContactId,
                            isReassigned: work.isReassigned,
                            assignedByUserId: work?.assignedByUserId || user.id,
                          });
                          setIsOpenReassign(false);
                        }}
                        className={
                          "mt-2 flex cursor-pointer items-center space-x-[4px] px-4 last-of-type:mb-2 hover:bg-blue-400 hover:text-white"
                        }
                        key={item?.id}>
                        <AvatarPlaceholder
                          size={"super-small"}
                          label={getInitials(
                            item?.userProfile?.firstName || "",
                            item?.userProfile?.lastName || "",
                          )}
                        />
                        <div>{`${item.userProfile?.firstName} ${item.userProfile?.middleName} ${item.userProfile?.lastName}`}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                "flex cursor-pointer items-center gap-2 rounded-[5px] px-2 py-1 text-base font-normal hover:bg-gray-300"
              }
              onClick={() => {
                setIsOpenActivation(true);
              }}>
              <BsArchive size={18} className={"text-purple"} />
              {isArchived ? "Move to active" : "Archive"}
            </div>
            {isArchived && (
              <div
                onClick={() => {
                  setConfirmDelete(true);
                }}
                className={
                  "flex cursor-pointer items-center gap-2 rounded-[5px] px-2 py-1 text-base font-normal hover:bg-gray-300"
                }>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className={"text-purple w-[18px]"}
                />
                Delete
              </div>
            )}
          </div>
        </EditDots>
      </div>
      {confirmDelete && (
        <ConfirmModal
          confirm={() => {
            deleteWork({ body: [work.id] });
          }}
          close={() => setConfirmDelete(false)}
          title={"Delete work?"}
          message={
            <>
              <div>You are about to delete this work item. All progress,</div>
              <div>
                {" "}
                including <span className={"font-bold"}>Files</span> and{" "}
                <span className={"font-bold"}>Notes</span>, will be deleted.
              </div>
              <div> Do you wish to proceed?</div>
            </>
          }
        />
      )}
      {isOpenActivation && (
        <ConfirmModal
          confirm={() => {
            !isArchived
              ? activateDeactivate({
                  body: [work.id] || [],
                  archiveWork: true,
                })
              : activateDeactivate({
                  body: [work.id] || [],
                  archiveWork: false,
                });
            setIsOpenActivation(false);
            setIsArchived(!work.isArchived);
          }}
          close={() => setIsOpenActivation(false)}
          title={!isArchived ? "Archive work?" : "Reactivate work?"}
          message={
            !isArchived ? (
              <div>
                You are about to archive this work. It will be
                <br />
                moved to the "Archived" section. You can always
                <br />
                reactivate them later
              </div>
            ) : (
              <div>
                You are about to reactivate this work. It will be <br />
                removed from the "Archived" section. You can always
                <br />
                archive them later
              </div>
            )
          }
        />
      )}
    </div>
  );
};
