import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { UpdateContactModel } from "../factories/contacts/models/update-contact.model";
import { UpdateOrganizationModel } from "../factories/contacts/models/update-organization.model";
import { customerFactory } from "../factories/customers/factory-customer";
import { CustomerDto } from "../factories/customers/models/customerDto";
import { CustomerModel } from "../factories/customers/models/customerModel";
import { billingAPI } from "../services/BillingService";
import {
  IContact,
  IContactProfile,
  IContactType,
  IOrganizationContact,
  IOrganizationType,
  SelectOptionType,
} from "../types/types";
import { contactNotesSlice } from "./ContactNotesSlice";
import { filesSlice } from "./FilesSlice";
import { linkedProfilesSlice } from "./LinkedProfilesSlice";
import { organizationNotesSlice } from "./OrganizationNotesSlice";
import { workSlice } from "./WorkSlice";

export const contactsSlice = createApi({
  reducerPath: "contactsSlice",
  tagTypes: [
    "Contacts",
    "ContactNotes",
    "ContactFiles",
    "OrgContacts",
    "ContactsWorks",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${devConfig.getServerBaseUrlForEnv()}`,
    prepareHeaders: (headers) => {
      const authHeader = getAuthHeader();
      headers.set("Authorization", authHeader.Authorization);
      return headers;
    },
  }),
  endpoints: (build) => ({
    //Contacts
    getAllContacts: build.query<IContact[], string>({
      query: (orgId = "") => ({
        url: "api/contacts/search",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["Contacts"],
    }),
    addPicture: build.mutation<
      unknown,
      { contactId?: string; businessContactId?: string; body: FormData }
    >({
      query: ({ contactId, businessContactId, body }) => ({
        url: "api/customers/add-image",
        method: "POST",
        params: { contactId, businessContactId },
        body,
      }),
      invalidatesTags: ["Contacts", "OrgContacts"],
    }),
    editPicture: build.mutation<
      unknown,
      { contactId?: string; businessContactId?: string; body: FormData }
    >({
      query: ({ contactId, businessContactId, body }) => ({
        url: "api/contacts/update-individual",
        method: "PUT",
        params: { contactId, businessContactId },
        body,
      }),
      invalidatesTags: ["Contacts", "OrgContacts"],
    }),
    deletePicture: build.mutation<
      unknown,
      { contactId?: string; businessContactId?: string }
    >({
      query: ({ contactId, businessContactId }) => ({
        url: "api/customers/remove-image",
        method: "DELETE",
        params: { contactId, businessContactId },
      }),
      invalidatesTags: ["Contacts", "OrgContacts"],
    }),
    getActiveCustomers: build.query<
      CustomerModel[],
      {
        orgId: string;
        isActive: boolean;
        userId: string;
        tagIds: string[];
        yearEndMonth: string[];
        customerName: string | null;
        sortOrder: "asc" | "desc";
        sortColumn: string | null;
      }
    >({
      query: (body) => ({
        url: "api/customers/get-active-or-inactive",
        method: "POST",
        body,
      }),
      providesTags: () => ["Contacts"],
    }),
    getArchivedCustomers: build.query<
      CustomerModel[],
      {
        orgId: string;
        isActive: boolean;
        userId: string;
        tagIds: string[];
        yearEndMonth: string[];
        customerName: string | null;
        sortOrder: "asc" | "desc";
        sortColumn: string | null;
      }
    >({
      query: (body) => ({
        url: "api/customers/get-active-or-inactive",
        method: "POST",
        body,
      }),
      providesTags: () => ["Contacts"],
    }),
    activateDeactivate: build.mutation<
      { data: CustomerModel[] },
      { body: string[]; activateCustomer: boolean }
    >({
      query: ({ body, activateCustomer }) => ({
        url: "api/customers/activate-or-deactivate",
        method: "Put",
        body,
        params: { activateCustomer },
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    getActiveContacts: build.query<IContact[], string>({
      query: (orgId = "") => ({
        url: "api/contacts/search",
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: IContact[]) => {
        return (
          res?.filter(
            (item) =>
              item.statusType && item.statusType.statusType === "Active",
          ) || []
        );
      },
      providesTags: () => ["Contacts"],
    }),
    getArchivedContacts: build.query<IContact[], string>({
      query: (orgId = "") => ({
        url: "api/contacts/search",
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: IContact[]) => {
        return (
          res?.filter(
            (item) =>
              item.statusType && item.statusType.statusType === "Unknown",
          ) || []
        );
      },
      providesTags: () => ["Contacts"],
    }),
    getContact: build.query<
      IContactProfile,
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        url: "api/contacts/get/" + customerId,
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["Contacts"],
    }),
    addContact: build.mutation({
      query: (body) => ({
        url: "api/contacts/add-individual",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Contacts"],
    }),
    editContact: build.mutation<unknown, UpdateContactModel>({
      query: (body) => ({
        url: "api/contacts/update-individual",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            billingAPI.util?.invalidateTags(["Invoices", "Payment"]),
          );
        });
      },
      invalidatesTags: ["Contacts"],
    }),
    activateContact: build.mutation<unknown, { contactId: string }>({
      query: (body) => ({
        url: "api/contacts/activate-contact",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: { id: body.contactId },
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    archiveContact: build.mutation<unknown, { contactId: string }>({
      query: (body) => ({
        url: "api/contacts/de-activate-contact",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: { id: body.contactId },
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    deleteContact: build.mutation<unknown, { body: string[] }>({
      query: ({ body }) => ({
        url: "api/customers/delete",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Contacts", "OrgContacts"],
    }),

    //Organizations
    getActiveOrganizations: build.query<IOrganizationContact[], string>({
      query: (orgId = "") => ({
        url: "api/business-contacts/get-all",
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: IOrganizationContact[]) => {
        return (
          res?.filter(
            (item) =>
              item.statusType && item.statusType.statusType === "Active",
          ) || []
        );
      },
      providesTags: () => ["OrgContacts"],
    }),
    getArchivedOrganizations: build.query<IOrganizationContact[], string>({
      query: (orgId = "") => ({
        url: "api/business-contacts/get-all",
        params: {
          orgId: orgId,
        },
      }),
      transformResponse: (res: IOrganizationContact[]) => {
        return (
          res?.filter(
            (item) =>
              item.statusType && item.statusType.statusType === "Unknown",
          ) || []
        );
      },
      providesTags: () => ["OrgContacts"],
    }),
    getAllOrgContacts: build.query<IOrganizationContact[], string>({
      query: (orgId = "") => ({
        url: "api/business-contacts/get-all",
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["OrgContacts"],
    }),
    getOrgContact: build.query<
      IOrganizationContact,
      { orgId: string; id: string }
    >({
      query: ({ orgId, id }) => ({
        url: "api/business-contacts/get-by-id/" + id,
        params: {
          orgId: orgId,
        },
      }),
      providesTags: () => ["OrgContacts"],
    }),
    addOrgContact: build.mutation({
      query: (body) => ({
        url: "api/business-contacts/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    editOrgContact: build.mutation<unknown, UpdateOrganizationModel>({
      query: (body) => ({
        url: "api/business-contacts/update-business-contact",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body,
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    activateOrganization: build.mutation<unknown, { id: string }>({
      query: (body) => ({
        url: "api/business-contacts/activate-business-contact",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: { id: body.id },
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),
    archiveOrganization: build.mutation<unknown, { id: string }>({
      query: (body) => ({
        url: "api/business-contacts/deactivate-business-contact",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: { id: body.id },
      }),
      invalidatesTags: ["OrgContacts", "Contacts"],
    }),

    //Imports
    importContacts: build.mutation<
      unknown,
      { body: FormData; locationId: string }
    >({
      query: ({ body, locationId }) => ({
        url: "api/contacts/import-contacts",
        method: "POST",
        params: { locationId },
        body,
      }),
      invalidatesTags: ["Contacts"],
    }),
    importOrganizations: build.mutation<
      unknown,
      { body: FormData; locationId: string }
    >({
      query: ({ body, locationId }) => ({
        url: "api/business-contacts/import-business-contacts",
        method: "POST",
        params: { locationId },
        body,
      }),
      invalidatesTags: ["OrgContacts"],
    }),
    //Common
    mergedContacts: build.mutation<
      unknown,
      { mergeCustomerId: string; mergeToCustomerId: string }
    >({
      query: (params) => ({
        url: "api/customers/merge-customers",
        method: "GET",
        params,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            billingAPI.util?.invalidateTags(["Invoices", "Payment"]),
          );
          api.dispatch(workSlice.util?.invalidateTags(["Work"]));
          api.dispatch(filesSlice.util?.invalidateTags(["ContactFiles"]));
          api.dispatch(
            linkedProfilesSlice.util?.invalidateTags(["LinkedProfile"]),
          );
          api.dispatch(contactNotesSlice.util?.invalidateTags(["ContactNote"]));
          api.dispatch(
            organizationNotesSlice.util?.invalidateTags(["OrganizationNote"]),
          );
        });
      },
      invalidatesTags: ["Contacts", "OrgContacts", "ContactsWorks"],
    }),
    getContactTypes: build.query<SelectOptionType[], void>({
      query: () => ({
        url: "api/contact-types/all",
        method: "GET",
      }),
      transformResponse: (response: IContactType[]) => {
        const result: SelectOptionType[] = response.map((item) => ({
          value: item?.id,
          label: item?.contactType,
        }));
        return result;
      },
    }),
    getSearchCustomers: build.query<
      CustomerModel[],
      { query: string; orgId: string; userId?: string | null }
    >({
      query: ({ query, orgId, userId }) => ({
        url: "api/customers/search",
        method: "GET",
        params: {
          query: query,
          orgId: orgId,
          userId: userId,
        },
      }),
      providesTags: ["Contacts"],
      transformResponse: (res: CustomerModel[]) => {
        return res.map((item) => ({
          ...item,
          name: item.name.replace(/\s+/g, " ").trim(),
        }));
      },
    }),
    getOrganizationTypes: build.query<SelectOptionType[], void>({
      query: () => ({
        url: "api/contact-types/organization-types",
        method: "GET",
      }),
      transformResponse: (response: IOrganizationType[]) => {
        const result: SelectOptionType[] = response.map((item) => ({
          value: item?.id,
          label: item?.organizationType,
        }));
        return result;
      },
    }),
    getCustomersByEmail: build.query<
      CustomerModel[],
      { email: string; orgId: string }
    >({
      query: ({ email, orgId }) => ({
        url: `api/customers/get-by-email/${email}`,
        method: "GET",
        params: { orgId },
      }),
      providesTags: ["OrgContacts", "Contacts"],
    }),
  }),
});
export const {
  //Contacts
  useGetAllContactsQuery,
  useGetActiveContactsQuery,
  useGetContactQuery,
  useAddContactMutation,
  useEditContactMutation,
  useActivateContactMutation,
  useArchiveContactMutation,
  useDeleteContactMutation,

  //Organizations
  useGetAllOrgContactsQuery,
  useGetActiveOrganizationsQuery,
  useGetOrgContactQuery,
  useAddOrgContactMutation,
  useEditOrgContactMutation,
  useActivateOrganizationMutation,
  useArchiveOrganizationMutation,

  //Imports
  useImportContactsMutation,
  useImportOrganizationsMutation,

  //Common
  useMergedContactsMutation,
  useGetContactTypesQuery,
  useGetOrganizationTypesQuery,

  //Customers (contacts + organizations)
  useGetActiveCustomersQuery,
  useGetArchivedCustomersQuery,
  useActivateDeactivateMutation,
  useGetSearchCustomersQuery,
  useGetCustomersByEmailQuery,
  useAddPictureMutation,
  useEditPictureMutation,
  useDeletePictureMutation,
} = contactsSlice;
