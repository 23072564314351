import React, { FC, useEffect, useRef, useState } from "react";
import { HiEllipsisVertical } from "react-icons/hi2";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Pages } from "../../../../app/constants/routes";
import { generateRoutePath } from "../../../../app/constants/routes/utils";
import { LABELS } from "../../../../app/constants/TextConstants";
import Button from "../../../../components/button/Button";
import { IconButton } from "../../../../components/button/IconButton";
import { LottieLoading } from "../../../../components/graphics/LottieLoading";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import IndividualContactSvg from "../../../../components/svg/IndividualContactSvg";
import { WarningExclaimSvg } from "../../../../components/svg/WarningExclaimSvg";
import { inactiveStatusId } from "../../../../constants/constants";
import { getLinkedFirm } from "../../../../sessionStorage/sessionStorage";
import {
  useActivateContactMutation,
  useArchiveContactMutation,
  useDeleteContactMutation,
  useGetContactQuery,
} from "../../../../slices/ContactsSlice";
import { CustomLink } from "../../../../support/CustomLink";
import { GetPageTitle } from "../../../../support/ScrollToTop";
import DeleteMessageContactPopup from "../../refactor/modals/DeleteMessageContactPopup";
import { MergeContactModal } from "../../refactor/modals/MergeContactModal";
import { toast } from "react-toastify";
import { UpdateAvatarModal } from "../../modals/UpdateAvatarModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { BsArchive } from "react-icons/bs";
import classNames from "classnames";
import { LuMerge } from "react-icons/lu";

export const ContactView: FC = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();

  const { data: contact, isLoading } = useGetContactQuery({
    customerId: customerId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });
  const [deleteContact, { isLoading: isLoadingDelete, isSuccess, isError }] =
    useDeleteContactMutation();
  const [activateContact, { isLoading: isLoadingActivate }] =
    useActivateContactMutation();
  const [archiveContact, { isLoading: isLoadingArchive }] =
    useArchiveContactMutation();

  const [isOpenMerge, setIsOpenMerge] = useState(false);
  const [isOpenActivation, setIsOpenActivation] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isUpdateAvatar, setIsUpdateAvatar] = useState(false);

  const { profile, works, time, notes, billing, emails } =
    Pages.contact.children;

  const ProfileComponent = profile.component;
  const WorksComponent = works.component;
  const TimeComponent = time.component;
  const NotesComponent = notes.component;
  const BillingComponent = billing.component;
  const EmailsComponent = emails.component;

  const isActive = contact?.contactStatusTypeEnumId !== inactiveStatusId;

  useEffect(() => {
    document.title = GetPageTitle(Pages.contact.label);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigate("/contacts");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  if (isLoading || isLoadingActivate || isLoadingArchive) {
    return <LottieLoading />;
  }

  if (!contact) {
    return null;
  }

  return (
    <>
      <div className={"grid grid-cols-1 justify-center"}>
        <div className={"w-full max-w-[1920px]"}>
          <div className={"flex w-full items-center gap-4"}>
            <div
              /*onClick={() => setIsUpdateAvatar(true)}*/
              className={
                "flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#002466]"
              }>
              {contact.imageURL ? (
                <img src={contact.imageURL} alt="avatar" />
              ) : (
                <IndividualContactSvg />
              )}
            </div>
            <div className={"flex items-end"}>
              <h1
                className={
                  "text-2xl"
                }>{`${contact?.firstName} ${contact?.middleName} ${contact?.lastName}`}</h1>
              {!isActive && (
                <div
                  className={"tooltip tooltip-bottom tooltip-warning"}
                  data-tip={LABELS.warnings.inactiveTooltip}>
                  <IconButton
                    size={"extra-small"}
                    colorType={"secondary"}
                    border={false}
                    extraCx={"ml-1 mt-1 rounded-full"}
                    IconSvg={WarningExclaimSvg}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={"my-4 flex w-full justify-between"}>
            <div className={"tabs"}>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    profile.path,
                  ]),
                }}>
                {profile.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    works.path,
                  ]),
                }}>
                {works.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    time.path,
                  ]),
                }}>
                {time.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    notes.path,
                  ]),
                }}>
                {notes.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    billing.path,
                  ]),
                }}>
                {billing.label}
              </CustomLink>
              <CustomLink
                to={{
                  pathname: generateRoutePath([
                    Pages.contact.path,
                    customerId || "",
                    emails.path,
                  ]),
                }}>
                {emails.label}
              </CustomLink>
            </div>
            <div>
              <div className={"dropdown dropdown-end"}>
                <div>
                  <Button
                    label={""}
                    colorType={"ghost"}
                    size={"custom"}
                    icon={<HiEllipsisVertical size={24} />}
                    extraClasses={"min-h-fit h-fit w-fit p-1"}
                  />
                </div>
                <div
                  className={classNames(
                    "dropdown-content rounded-[5px] bg-white shadow",
                    isActive ? "w-[120px]" : "w-[180px]",
                  )}>
                  <div
                    onMouseDown={() => setIsOpenActivation(true)}
                    className={
                      "flex cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] hover:bg-gray-300"
                    }>
                    <BsArchive size={18} className={"text-purple"} />
                    {isActive ? "Archive" : "Move to active"}
                  </div>
                  {isActive && (
                    <div
                      onMouseDown={() => setIsOpenMerge(true)}
                      className={
                        "flex cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] hover:bg-gray-300"
                      }>
                      <LuMerge size={18} className={"text-purple"} />
                      Merge
                    </div>
                  )}
                  {!isActive && (
                    <div
                      onMouseDown={() => setIsOpenDelete(true)}
                      className={
                        "flex cursor-pointer items-center gap-2 rounded-[5px] px-[15px] py-[5px] hover:bg-gray-300"
                      }>
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        className={"text-purple w-[18px]"}
                      />
                      Delete
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Routes>
            <Route
              index
              element={
                <Navigate
                  to={{
                    pathname: profile.path,
                  }}
                  replace={true}
                />
              }
            />
            <Route
              path={profile.path}
              element={<ProfileComponent contact={contact} />}
            />
            <Route
              path={works.path}
              element={<WorksComponent contact={contact} />}
            />
            <Route
              path={time.path}
              element={<TimeComponent contact={contact} />}
            />
            <Route
              path={notes.path}
              element={<NotesComponent contact={contact} />}
            />
            <Route path={billing.path} element={<BillingComponent />} />
            <Route
              path={emails.path + "/*"}
              element={<EmailsComponent contact={contact} />}
            />
            <Route path={"*"} element={<>Not found</>} />
          </Routes>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
      {isOpenActivation && (
        <ConfirmModal
          confirm={() => {
            isActive
              ? archiveContact({ contactId: contact?.contactId || "" })
              : activateContact({ contactId: contact?.contactId || "" });
            setIsOpenActivation(false);
          }}
          close={() => setIsOpenActivation(false)}
          title={isActive ? "Archive client?" : "Move to active client?"}
          message={
            isActive ? (
              <div>
                You are about to archive this client. Their profile will be
                <br />
                moved to the "Archived" section. You can always
                <br />
                reactivate them later
              </div>
            ) : (
              <div>
                You are about to reactivate this client. Their profile will be{" "}
                <br />
                moved to the "Active" section. You can always
                <br />
                archive them later
              </div>
            )
          }
        />
      )}
      {isUpdateAvatar && (
        <UpdateAvatarModal
          contact={contact}
          isActive={isUpdateAvatar}
          onClose={() => setIsUpdateAvatar(false)}
        />
      )}
      {isOpenMerge && contact && (
        <MergeContactModal
          customerId={customerId || ""}
          isActive={isOpenMerge}
          onClose={() => setIsOpenMerge(false)}
          name={`${contact?.firstName} ${contact?.lastName}`}
          type={"contact"}
        />
      )}
      {isOpenDelete && (
        <DeleteMessageContactPopup
          isOpen={isOpenDelete}
          isLoading={isLoadingDelete}
          closePopup={() => setIsOpenDelete(false)}
          confirmDeleteContact={() => {
            setIsOpenDelete(false);
            deleteContact({ body: [contact?.customerId] || [] });
          }}
        />
      )}
    </>
  );
};
