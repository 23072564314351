import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  useArchiveUserMutation,
  useGetUsersQuery,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { CustomLink } from "../../../support/CustomLink";
import { FormSection } from "../../../support/FormSection";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser } from "../../../types/types";
import { EditUserView } from "./EditUserView";
import { getInitials } from "../../../constants/constants";

const UsersActiveList = () => {
  const navigate = useNavigate();

  const { data: users, isLoading } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const [archiveUser, { isError }] = useArchiveUserMutation();

  useEffectOnce(() => {
    document.title = GetPageTitle("Firm users");
  });

  const [isEdit, setIsEdit] = useState(false);

  const [currentUser, setCurrentUser] = useState<IUser | null>(null);

  if (isLoading) {
    return <LottieLoading />;
  }

  const userRows = users?.map((user) => {
    return (
      <tr key={user?.id}>
        <td>
          <div className={"flex items-center gap-2"}>
            <AvatarPlaceholder
              size={"extra-small"}
              type={"custom"}
              avatarCustomColor={user?.userProfile?.color}
              label={getInitials(
                user?.userProfile?.firstName || "",
                user?.userProfile?.lastName || "",
              )}
            />
            <div
              className={
                "max-w-[300px] truncate whitespace-nowrap pl-2 text-sm font-semibold normal-case text-gray-800"
              }>
              {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
            </div>
          </div>
        </td>
        <td>
          <div className={"text-[14px]"}>{user?.userProfile?.email}</div>
        </td>
        <td>
          <div className={"text-[14px]"}>
            {user?.nylasToken?.email
              ? user?.nylasToken?.email
              : "Not connected"}
          </div>
        </td>
        <td>
          <div className={"flex items-center"}>
            <div className={"badge badge-outline text-base capitalize"}>
              {user?.userRoleEnum?.userRole}
            </div>
          </div>
        </td>
        <td>
          <div className={"flex justify-end"}>
            <EditDots>
              <div className={""}>
                <div
                  onClick={() => {
                    setCurrentUser(user);
                    setIsEdit(true);
                  }}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Edit
                </div>
                <div
                  onClick={() => archiveUser(user)}
                  className={
                    "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                  }>
                  Archive
                </div>
              </div>
            </EditDots>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection
        customCx={"my-0 p-0"}
        extraCx={"space-y-0 h-fit text-[16px]"}
        viewFormat={"custom"}
        name={""}
        showHeading={false}>
        <div className={"p-6"}>
          <div className={"mb-4"}>
            <div className={"tabs mb-4"}>
              <CustomLink to={"./"}>Active</CustomLink>
              <CustomLink to={"../archived"}>Archived</CustomLink>
            </div>
            <div className={"text-lg font-bold "}>Users</div>
          </div>
          <div>
            <button
              onClick={() => navigate("./add")}
              className={
                "bg-purple mb-6 flex items-center justify-center rounded-[7px] px-[20px] py-[8px] text-[14px] text-white hover:bg-purple-800"
              }>
              Add User
            </button>
          </div>
          {isError && (
            <div className={"text-md text-red-600"}>
              Something went wrong. Try again or contact support
            </div>
          )}
          <div className={"flex"}>
            <table
              className={
                "table table-compact w-full overflow-x-auto  md:table-normal"
              }>
              <thead>
                <tr>
                  <th>
                    <div className={"text-md"}>Name</div>
                  </th>
                  <th>
                    <div className={"text-md"}>Email</div>
                  </th>
                  <th>
                    <div className={"text-md"}>Connected email</div>
                  </th>
                  <th>
                    <div className={"text-md"}>Role</div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{userRows}</tbody>
            </table>
          </div>
        </div>
      </FormSection>
      {currentUser && (
        <EditUserView
          isActive={isEdit}
          closeEdit={() => setIsEdit(false)}
          user={currentUser}
        />
      )}
    </>
  );
};

export default UsersActiveList;
