import { ChevronDown, ChevronUp } from "baseui/icon";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import * as devConfig from "../../../app/configs/devConfig";
import { getAuthHeader } from "../../../app/globalState/GlobalAuthState";
import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import EditDots from "../../../components/EditDots/EditDots";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import {
  IInvoiceCustomers,
  InvoiceItem,
  SortEnum,
  useDeleteInvoiceMutation,
  useGetBilledWorksQuery,
  useGetBillingLogoQuery,
  useGetInvoiceByIdQuery,
  useGetInvoiceStatusesQuery,
  useGetPaymentByInvoiceIdQuery,
  useGetPaymentsRecievedQuery,
  useGetTaxRateQuery,
  useUpdateInvoiceMutation,
} from "../../../services/BillingService";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { IFirm } from "../../../types/types";
import DeletePopup from "../DeletePopup";
import DropStatus from "../DropStatus";
import PayInvoiceModal from "../payments/PayInvoiceModal";
import PDFPreview from "./generatePDF";
import { navigateToContact } from "../../contacts/utils/navigateToContacts";
import { IoTimeOutline } from "react-icons/io5";
import { ViewWork } from "./ViewWork";
import ClockSvg from "../../../components/svg/clock-icon.svg";
import Button from "../../../components/button/Button";
import { ComposeEmailModal } from "../../contacts/modals/ComposeEmailModal";
import { Attachment } from "../../../factories/emails/models/send-email.model";

const discountTypes = {
  "%": "89EDC863-9055-479B-AD08-554C0418A59F",
  $: "771B703A-5E94-4271-988A-206C0B48F7A8",
};

const InvoiceDetails = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<"details" | "edit">>;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [sort, setSort] = useState<{ name: SortEnum; order: boolean }>({
    name: SortEnum.PaymentDate,
    order: false,
  });

  const [isOpenPay, setIsOpenPay] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [viewWork, setViewWork] = useState(false);
  const [workItem, setWorkItem] = useState<InvoiceItem>();
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  /*
  const [isOpenCompose, setIsOpenCompose] = useState(false);
*/
  /*  const [invoiceFile, setInvoiceFile] = useState<Attachment>({
    content_type: "",
    filename: "",
    content: "",
  });*/

  const {
    data: invoice,
    isLoading: isLoadingInvoice,
    isFetching,
  } = useGetInvoiceByIdQuery(`${id}`);

  const { data: logo, isLoading: isLoadingLogo } = useGetBillingLogoQuery(
    getLinkedFirm()?.orgId || "",
  );

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetInvoiceStatusesQuery(getLinkedFirm()?.orgId || "");

  const [updateInvoice, { isLoading: isLoadingUpdate }] =
    useUpdateInvoiceMutation();

  const {
    data: payments,
    isLoading: isLoadingPayments,
    refetch,
  } = useGetPaymentByInvoiceIdQuery(invoice?.id || "");

  const { data: paymentsRecieved, isLoading: isLoadingPaymentsRecieved } =
    useGetPaymentsRecievedQuery(`${id}`);

  const { data: taxRate, isLoading: isLoadingTaxRate } = useGetTaxRateQuery(
    getLinkedFirm()?.orgId,
  );

  const [deleteInvoice, { isSuccess: isSuccessDelete }] =
    useDeleteInvoiceMutation();

  const [firm, setFirm] = useState<IFirm | null>(null);
  const [isLoadingFirm, setIsLoadingFirm] = useState(false);

  const handleSort = (name: SortEnum) => {
    if (name === sort.name) {
      setSort({ name, order: !sort.order });
    } else {
      setSort({ name, order: true });
    }
  };
  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };
  const getFirm = async () => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/org/${getLinkedFirm()?.orgId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      setFirm(data);
    }
    setIsLoadingFirm(false);
  };

  const subtotal = useMemo(() => {
    let value = 0;
    invoice?.invoiceItem?.forEach((item) => (value += item.amount));
    return value;
  }, [invoice]);

  const discountValue = useMemo(() => {
    if (invoice?.discountTypeId === discountTypes.$.toLowerCase()) {
      return invoice?.discount || 0;
    }
    if (invoice?.discountTypeId === discountTypes["%"].toLowerCase()) {
      return (subtotal * (invoice?.discount || 0)) / 100;
    }
    return 0;
  }, [invoice, subtotal]);

  const taxValue = useMemo(() => {
    if (invoice?.isIncluded) {
      const tax: number = (invoice?.tax || 0) / 100 + 1;
      const subtotalWithoutTax = invoice?.invoiceItem.reduce(
        (acc, item) => acc + item.amount / tax,
        0,
      );
      return (
        (((subtotalWithoutTax || 0) - (discountValue || 0)) *
          (invoice?.tax || 0)) /
        100
      );
    }
    return (
      (((subtotal || 0) - (discountValue || 0)) * (invoice?.tax || 0)) / 100
    );
  }, [subtotal, discountValue, invoice]);

  const paymentsItems = useMemo(() => {
    const result: any[] = [];
    if (payments) {
      payments.forEach((payment) => {
        const i = payment.paymentItem.find(
          (item) => item.invoiceId === invoice?.id,
        );
        if (i) {
          result.push({
            ...i,
            customer: payment?.customer,
            customerId: payment?.customerId,
            paymentMethod: payment?.paymentMethod,
            note: payment?.notes,
            paymentDate: payment?.paymentDate,
          });
        }
      });
    }
    return result;
  }, [payments]);

  const updateStatus = (statusId: string) => {
    updateInvoice({
      id: invoice?.id || "",
      invoiceStatusId: statusId || null,
      customerId: invoice?.customerId,
      invoiceClientsIds: invoice?.invoiceClientsIds,
      createdByUserId: invoice?.createdByUserId,
      invoiceDate: invoice?.invoiceDate,
      termId: invoice?.termId,
      dueDate: invoice?.dueDate,
      invoiceNo: invoice?.invoiceNo,
      tax: invoice?.tax,
      orgId: invoice?.orgId,
      total: invoice?.total,
      discount: invoice?.discount,
      balance: invoice?.balance,
      discountTypeId: invoice?.discountTypeId,
      paymentRecieved: invoice?.paymentRecieved,
      assignedToUserId: invoice?.assignedToUserId,
      invoiceItem: invoice?.invoiceItem,
    });
  };

  useEffect(() => {
    if (invoice?.id) {
      refetch();
    }
  }, [invoice]);

  useEffect(() => {
    if (isSuccessDelete) {
      navigate("/billing/invoices");
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    setIsLoadingFirm(true);
    getFirm();
  }, []);

  if (
    isLoadingInvoice ||
    isLoadingLogo ||
    isLoadingTaxRate ||
    isLoadingPaymentsRecieved ||
    isLoadingPayments ||
    isLoadingFirm ||
    isLoadingStatuses
  ) {
    return <LottieLoading />;
  }

  const tableRows = invoice?.invoiceItem?.map((item, index) => {
    return (
      <tr
        key={index}
        className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {item?.billingService?.name || "N/A"}
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {item?.description || ""}
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          {item?.quantity || 0}
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(item?.rate || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {(item?.amount || 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td className={"w-6 bg-transparent px-4 py-2 text-lg text-gray-300"}>
          {item.invoiceType === "billedItem" && (
            <>
              <IoTimeOutline
                className={"text-purple h-6 w-6 cursor-pointer"}
                onClick={() => {
                  setWorkItem(item);
                  setViewWork(true);
                }}
              />
              {viewWork && (
                <ViewWork
                  billedWork={workItem}
                  isActive={viewWork}
                  setCollapsedItems={setCollapsedItems}
                  collapsedItems={collapsedItems}
                  setViewWork={setViewWork}
                  totalRate={totalRate}
                />
              )}
            </>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection
        name={"Invoice"}
        classForTitle={"p-0"}
        extraCx={"shadow-box"}
        RightSideComponent={
          <div className={"flex items-center gap-4"}>
            <DropStatus
              isLoading={isLoadingUpdate || isFetching}
              invoiceStatus={invoice?.invoiceStatus || null}
              paymentStatus={null}
              statuses={statuses || []}
              selectStatus={(status) => {
                updateStatus(status?.id || "");
              }}
            />
            <div
              className={
                "border-purple relative flex items-center justify-center rounded-[8px] border-[1px] transition-all duration-200"
              }>
              <EditDots extraPosition={"top-[calc(100%+4px)] right-0"}>
                <div className={""}>
                  <div
                    onClick={() => setView("edit")}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Edit
                  </div>
                  <div
                    onClick={() => setIsOpenPay(true)}
                    className={
                      "py -[5px] cursor-pointer    px-[10px] hover:bg-gray-200"
                    }>
                    Pay
                  </div>
                  <div
                    onClick={() => {
                      if (invoice) {
                        setIsOpenDelete(true);
                      }
                    }}
                    className={
                      "cursor-pointer px-[10px] py-[5px] hover:bg-gray-200"
                    }>
                    Delete
                  </div>
                </div>
              </EditDots>
            </div>
          </div>
        }>
        <div className={"flex items-start justify-between"}>
          <div className={"flex flex-col gap-6"}>
            <div>
              <div className={"mb-2 text-base uppercase text-gray-400"}>
                Client
              </div>
              <div className={"flex flex-col gap-4"}>
                <div className={"flex  items-center"}>
                  <AvatarPlaceholder
                    size={"extra-small"}
                    type={
                      invoice?.customer?.businessContactId ? "purple" : "blue"
                    }
                    label={getInitialsFromFullName(
                      invoice?.customer?.name || "",
                    )}
                  />
                  <div
                    onClick={() =>
                      navigate(
                        navigateToContact({
                          type: invoice?.customer?.businessContactId
                            ? "organization"
                            : "contact",
                          customerId: invoice?.customer?.id || "",
                          id: invoice?.customer?.businessContactId || "",
                        }),
                      )
                    }
                    className={
                      "link link-primary max-w-[300px] cursor-pointer pl-2 text-base font-semibold hover:underline"
                    }>
                    {invoice?.customer?.name}
                  </div>
                </div>
                {invoice?.invoiceCustomers?.map((item: IInvoiceCustomers) => (
                  <div className={"flex items-center"} key={item?.id}>
                    <AvatarPlaceholder
                      size={"extra-small"}
                      type={
                        item?.customers?.businessContactId ? "purple" : "blue"
                      }
                      label={getInitialsFromFullName(
                        item?.customers?.name || "",
                      )}
                    />
                    <div
                      onClick={() =>
                        navigate(
                          navigateToContact({
                            type: item?.customers?.businessContactId
                              ? "organization"
                              : "contact",
                            customerId: invoice?.customer?.id || "",
                            id: item?.customers?.businessContactId || "",
                          }),
                        )
                      }
                      className={
                        "link link-primary max-w-[300px] cursor-pointer pl-2 text-base font-semibold hover:underline"
                      }>
                      {item?.customers?.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={"flex items-start gap-6"}>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Invoice date
                </div>
                <div className={"text-base font-medium"}>
                  {moment(invoice?.invoiceDate).format("YYYY-MM-DD")}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Terms
                </div>
                <div className={"text-base  font-medium"}>
                  {invoice?.invoiceTerms?.termName || "N/A"}
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Due date
                </div>
                <div className={"text-base font-medium"}>
                  {moment(invoice?.dueDate).format("YYYY-MM-DD")}
                </div>
              </div>
            </div>
            {invoice?.assignedToUser && (
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Assignee
                </div>
                <div className={"flex items-center"}>
                  <AvatarPlaceholder
                    size={"extra-small"}
                    label={getInitials(
                      invoice?.assignedToUser?.userProfile?.firstName || "",
                      invoice?.assignedToUser?.userProfile?.lastName || "",
                    )}
                  />
                  <div
                    className={
                      "whitespace-nowrap pl-2 text-base font-medium normal-case"
                    }>
                    {`${invoice?.assignedToUser?.userProfile?.firstName} ${invoice?.assignedToUser?.userProfile?.lastName}`}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className={"mb-2 text-base uppercase text-gray-400"}>
              Invoice No
            </div>
            <div
              className={
                "input input-bordered flex items-center justify-center font-medium"
              }>
              {invoice?.invoiceNo}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection name={""} classForTitle={"p-0"} extraCx={"shadow-box"}>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300 bg-gray-200"}>
            <tr>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    " flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Service
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Description
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Qty
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Rate
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Amount
                </div>
              </th>
              <th
                className={
                  "items-center py-4 pl-4 pr-8 text-gray-800 last:pr-4"
                }>
                <img
                  src={ClockSvg}
                  alt={"Time"}
                  className={"h-[24px] w-[24px]"}
                />
              </th>
            </tr>
          </thead>
          <tbody className={"text-[14px]"}>{tableRows}</tbody>
        </table>

        <div className={"flex w-full justify-between"}>
          <div>
            <div className={"mb-2 mt-20 text-base font-semibold text-gray-800"}>
              Notes
            </div>
            <div
              className={
                "textarea textarea-bordered min-h-[75px] min-w-[300px] max-w-[500px] break-all"
              }>
              {invoice?.notes}
            </div>
          </div>
          <div className={"flex min-w-[300px] max-w-[500px] flex-col"}>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Subtotal:</span> $
              {(subtotal || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Discount:</span> $
              {discountValue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div className={"mb-2 flex w-full justify-between gap-2"}>
              <div className={"flex max-w-[200px] gap-2"}>
                <div className={"text-base font-semibold text-gray-800"}>
                  {taxRate?.[0]?.taxName}:
                </div>
                <div className={"text-base font-semibold text-gray-800"}>
                  {invoice?.tax}%
                </div>
              </div>
              <div className={"flex items-center gap-2"}>
                {invoice?.isIncluded && (
                  <div
                    className={
                      "input input-bordered flex h-fit min-h-[10px] items-center  gap-2 rounded-md px-2 text-gray-500"
                    }>
                    <span className={"font-normal"}>
                      Includes {taxRate?.[0]?.taxName || ""} on
                    </span>
                    <span className={"font-semibold"}>
                      ${" "}
                      {(subtotal - taxValue - discountValue).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </span>
                  </div>
                )}
                <div className={"text-base font-semibold text-gray-800"}>
                  $
                  {(taxValue || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Total:</span> $
              {(invoice?.total || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Payment received:</span> $
              {(paymentsRecieved || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={
                "mb-2 flex w-full items-end justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Balance due:</span>{" "}
              <span className={"text-3xl font-bold"}>
                $
                {(
                  (invoice?.total || 0) - (paymentsRecieved || 0)
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </div>
        <div className={"flex w-full justify-end"}>
          {/* <div className={"mr-4"}>
            <Button
              label={"Send invoice"}
              onClick={() => setIsOpenCompose(true)}
            />
          </div>*/}
          <PDFPreview
            invoice={invoice}
            firm={firm}
            logo={logo}
            paymentsRecieved={paymentsRecieved || 0}
            taxName={taxRate?.[0]?.taxName || ""}
            /*setInvoiceFile={setInvoiceFile}*/
          />
        </div>
      </FormSection>
      <div className={"text-base font-semibold text-gray-800"}>
        {moment(invoice?.createdAt).format("MM/DD/YYYY")} Invoice created by{" "}
        {invoice?.createdByUser &&
          `${invoice?.createdByUser?.userProfile?.firstName} ${invoice?.createdByUser?.userProfile?.lastName}`}
      </div>
      {payments && payments?.length > 0 && (
        <FormSection
          name={"Payments"}
          classForTitle={"p-0"}
          extraCx={"shadow-box"}>
          <table className={"w-full"}>
            <thead className={"border-b-[1px] border-gray-300"}>
              <tr>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                  <div
                    className={
                      "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                    }
                    onClick={() => handleSort(SortEnum.Customer)}>
                    <div>Contact</div>
                    {sort?.name === SortEnum.Customer && (
                      <div className={"relative left-0 top-[-4px]"}>
                        {sort?.order ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                  <div
                    className={
                      "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                    }
                    onClick={() => handleSort(SortEnum.PaymentDate)}>
                    <div>Payment date</div>
                    {sort?.name === SortEnum.PaymentDate && (
                      <div className={"relative left-0 top-[-4px]"}>
                        {sort?.order ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                  <div
                    className={
                      "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                    }
                    onClick={() => handleSort(SortEnum.Amount)}>
                    <div>Amount</div>
                    {sort?.name === SortEnum.Amount && (
                      <div className={"relative left-0 top-[-4px]"}>
                        {sort?.order ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                  <div
                    className={
                      "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                    }
                    onClick={() => handleSort(SortEnum.PaymentMethod)}>
                    <div>Method</div>
                    {sort?.name === SortEnum.PaymentMethod && (
                      <div className={"relative left-0 top-[-4px]"}>
                        {sort?.order ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}>
                  <div
                    className={
                      "flex cursor-pointer whitespace-nowrap text-sm normal-case"
                    }
                    onClick={() => handleSort(SortEnum.Notes)}>
                    <div>Notes</div>
                    {sort?.name === SortEnum.Notes && (
                      <div className={"relative left-0 top-[-4px]"}>
                        {sort?.order ? (
                          <div className={"absolute"}>
                            <ChevronUp size={26} />
                          </div>
                        ) : (
                          <div className={"absolute"}>
                            <ChevronDown size={26} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </th>
                <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
              </tr>
            </thead>
            <tbody>
              {paymentsItems?.map((payment, index) => {
                return (
                  <tr
                    key={index}
                    className={
                      "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                    }>
                    <td className={"bg-transparent px-[16px] py-[8px]"}>
                      <div className={"flex items-center"}>
                        <div className={"flex items-center text-[14px]"}>
                          <AvatarPlaceholder
                            size={"extra-small"}
                            type={
                              invoice?.customer?.businessContactId
                                ? "purple"
                                : "blue"
                            }
                            label={getInitialsFromFullName(
                              invoice?.customer?.name || "",
                            )}
                          />
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                navigateToContact({
                                  type: invoice?.customer?.businessContactId
                                    ? "organization"
                                    : "contact",
                                  customerId: invoice?.customer?.id || "",
                                  id:
                                    invoice?.customer?.businessContactId || "",
                                }),
                              );
                            }}
                            className={
                              "link-primary cursor-pointer pl-2 text-sm font-semibold hover:underline"
                            }>
                            {invoice?.customer?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={
                        "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                      }>
                      {moment(payment?.paymentDate).format("MM/DD/YYYY")}
                    </td>
                    <td
                      className={
                        "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                      }>
                      $
                      {(payment?.amountPaid || 0).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td
                      className={
                        "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                      }>
                      {payment?.paymentMethod?.paymentMethod}
                    </td>
                    <td
                      className={
                        "bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
                      }>
                      {payment?.note}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </FormSection>
      )}
      {/*{isOpenCompose &&
      (invoice?.customer?.businessContact?.email ||
        invoice?.customer?.contact?.email) ? (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
          bulk={true}
          invoiceFile={invoiceFile}
          invoiceEmail={
            invoice?.customer.businessContact.email ||
            invoice?.customer.contact.email
          }
        />
      ) : (
        <ComposeEmailModal
          isActive={isOpenCompose}
          onClose={() => setIsOpenCompose(false)}
          bulk={true}
        />
      )}*/}
      {isOpenPay && (
        <PayInvoiceModal close={() => setIsOpenPay(false)} invoice={invoice} />
      )}
      {isOpenDelete && (
        <DeletePopup
          onClose={() => setIsOpenDelete(false)}
          isActive={isOpenDelete}
          onDelete={() => {
            if (invoice) {
              deleteInvoice(invoice);
            }
            setIsOpenDelete(false);
          }}
        />
      )}
    </>
  );
};

export default InvoiceDetails;
