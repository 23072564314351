import React, { useEffect, useState } from "react";
import { useDebounce, useEffectOnce } from "usehooks-ts";

import { AvatarPlaceholder } from "../../../components/avatar/AvatarPlaceholder";
import Button from "../../../components/button/Button";
import { OutlineButton } from "../../../components/button/OutlineButton";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  useEditUserMutation,
  useGetPermissionsQuery,
  useGetUserRoleEnumQuery,
} from "../../../slices/UserSlice";
import { GetPageTitle } from "../../../support/ScrollToTop";
import { IUser, IUserRoleEnum, SelectOptionType } from "../../../types/types";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../constants/constants";
import classNames from "classnames";
import CustomSelect from "../../../components/select/Select";
import { useGetSearchCustomersQuery } from "../../../slices/ContactsSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { ViewContactList } from "./ViewContactList";
import { useForm } from "react-hook-form";

type Props = {
  isActive: boolean;
  closeEdit: () => void;
  user: IUser;
};

export const EditUserView: React.FC<Props> = ({
  isActive,
  closeEdit,
  user,
}) => {
  const [role, setRole] = useState<string>(user?.userRoleEnum?.userRole);
  const { data: permissions } = useGetPermissionsQuery();

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
    user.permissionIds || [],
  );
  const [assignedContacts, setAssignedContacts] = useState<SelectOptionType[]>(
    [],
  );
  const [assignedAll, setAssignedAll] = useState(user.assignedAllClients);
  const [userValue, setUserValue] = useState("");
  const [viewList, setViewList] = useState(false);
  const searchContactsDebounce = useDebounce(userValue, 300);
  const [rate, setRate] = useState(user.hourlyRate);
  const [avatarColor, setAvatarColor] = useState(
    user.userProfile?.color || "rgb(30, 58, 138)",
  );

  const { data: userRoleEnum, isLoading } = useGetUserRoleEnumQuery();
  const [editUser, { isSuccess, isError }] = useEditUserMutation();
  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      orgId: getLinkedFirm()?.orgId || "",
      query: searchContactsDebounce || "a",
      userId: undefined,
    });

  useEffectOnce(() => {
    document.title = GetPageTitle("Edit user");
  });

  useEffect(() => {
    if (contacts) {
      const defaultValues: SelectOptionType[] = contacts
        .filter((item) => user.assignedCustomerIds.includes(item.id))
        .map((contact) => {
          return {
            value: contact?.id,
            label: `${contact.name}`,
            avatar: getInitialsFromFullName(contact?.name),
            avatarColor: contact.businessContactId ? "purple" : "blue",
          };
        });
      setAssignedContacts(defaultValues);
    }
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      closeEdit();
    }
  }, [isSuccess]);

  const onSubmit = () => {
    editUser({
      ...user,
      userRoleEnumId:
        userRoleEnum?.find((item) => item.userRole === role)?.id || "",
      permissionIds: selectedPermissions,
      assignedAllClients: assignedAll,
      assignedCustomerIds: assignedAll
        ? []
        : assignedContacts.map((i) => i.value),
      hourlyRate: rate,
      color: avatarColor,
    });
  };

  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };
  useEffect(() => {
    setRole(user.userRoleEnum.userRole);
    setSelectedPermissions(user.permissionIds);
    setAssignedAll(user.assignedAllClients);
    setRate(user.hourlyRate);
  }, [user.id]);

  useEffect(() => {
    if (user) {
      setAvatarColor(user.userProfile?.color || "rgb(30, 58, 138)");
    }
  }, [user]);

  useEffect(() => {
    if (role === "Admin" && permissions) {
      setSelectedPermissions(permissions.map((i) => i.id));
    }
    if (role === "Staff (No Settings)") {
      setSelectedPermissions(
        permissions
          ?.filter((i) => i.id !== "3476ece8-0485-4b7f-98b1-378199bf8767")
          .map((i) => i.id) || [],
      );
    }
    if (role === "Staff (No Settings, No Billing)") {
      setSelectedPermissions(
        permissions
          ?.filter(
            (i) =>
              i.id !== "3476ece8-0485-4b7f-98b1-378199bf8767" &&
              i.id !== "434d4588-3532-4e3a-ac3c-d294ef2d76a6",
          )
          .map((i) => i.id) || [],
      );
    }
    if (role === "Custom" && permissions) {
      setSelectedPermissions(user.permissionIds || []);
    }
  }, [role, user, permissions]);

  const sortedRoles: IUserRoleEnum[] = [...(userRoleEnum || [])].sort(
    (a, b) => {
      if (a.userRole === "Custom") return 1;
      if (b.userRole === "Custom") return -1;
      return 0;
    },
  );

  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-[25%] max-w-[90%]"}>
        {isLoading ? (
          <div className={"max-w-[300px]"}>
            <LottieLoading />
          </div>
        ) : (
          <>
            <div className={"mb-4 text-base font-semibold text-gray-800"}>
              Edit user role
            </div>
            <div className={"flex justify-between"}>
              <div className={"mb-4 flex items-center"}>
                <AvatarPlaceholder
                  size={"extra-small"}
                  type={"custom"}
                  avatarCustomColor={avatarColor}
                  label={getInitials(
                    user?.userProfile?.firstName || "",
                    user?.userProfile?.lastName || "",
                  )}
                />
                <div className={"flex flex-col pl-2"}>
                  <div className={"text-[14px] capitalize"}>
                    {`${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
                  </div>
                </div>
              </div>
              <input
                className={"cursor-pointer"}
                type="color"
                value={avatarColor}
                onChange={(e) => {
                  setAvatarColor(e.target.value);
                }}
              />
            </div>
            <div>
              <div className={"form-control mb-8 w-full"}>
                <label className={"label"}>
                  <span className={"label-text"}>Preset roles:</span>
                </label>
                <select
                  className={"select select-bordered w-full"}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}>
                  {sortedRoles?.map((item) => (
                    <option key={item.id} value={item.userRole}>
                      {item.userRole}
                    </option>
                  ))}
                </select>
                <div className={"flex items-center  gap-2 pt-4"}>
                  <div className={"label-text"}>Hourly rate</div>
                  <div
                    className={
                      "focus:border-purple flex w-[100px] items-center rounded-md border border-gray-200 px-4 py-2 focus:border"
                    }>
                    $
                    <input
                      type="number"
                      value={rate}
                      className={
                        "w-[70px] text-base [appearance:textfield] focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      }
                      onChange={(e) => setRate(Number(e.target.value))}
                    />
                  </div>
                </div>

                <div>
                  <div className={"my-4 text-base font-semibold"}>
                    Permissions:
                  </div>
                  {permissions?.map((item) => (
                    <div key={item.id}>
                      <div className={"grid grid-cols-2 items-center "}>
                        <div className={"label-text mr-4 h-6"}>
                          {item.permission}
                        </div>
                        <div className={"flex items-center"}>
                          <input
                            type="checkbox"
                            className={classNames(
                              "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]",
                              selectedPermissions.includes(item.id)
                                ? "border-[#7c66f0] bg-[#7c66f0]"
                                : "border-gray-300 bg-gray-300",
                            )}
                            checked={selectedPermissions.includes(item.id)}
                            onChange={(e) => {
                              if (role === "Admin") {
                                return;
                              }
                              if (role === "Staff (No Settings)") {
                                return;
                              }
                              if (role === "Staff (No Settings, No Billing)") {
                                return;
                              }
                              const checked = e.target.checked;
                              setSelectedPermissions((prev) =>
                                !checked
                                  ? prev.filter((i) => i !== item.id)
                                  : [...prev, item.id],
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div className={"my-4 text-base font-semibold"}>
                    Client access:
                  </div>
                  <div className={"grid grid-cols-2 items-center"}>
                    <div className={"label-text mr-4"}>All contacts</div>
                    <div className={"flex items-center"}>
                      <input
                        type="checkbox"
                        className={classNames(
                          "toggle toggle-accent mr-4 h-5 w-11 border-[#7c66f0] bg-[#7c66f0]",
                          assignedAll
                            ? "border-[#7c66f0] bg-[#7c66f0]"
                            : "border-gray-300 bg-gray-300",
                        )}
                        checked={assignedAll}
                        onChange={(e) =>
                          e.target.checked
                            ? setAssignedAll(true)
                            : setAssignedAll(false)
                        }
                      />
                    </div>
                  </div>
                  {!assignedAll && (
                    <>
                      <div
                        className={
                          "label-text mb-1 mr-4 mt-2 pl-1 text-gray-500"
                        }>
                        Select client(s)
                      </div>
                      <div className={"flex items-center gap-3"}>
                        <div className={"mr-4 h-[42px] w-[301px]"}>
                          <CustomSelect
                            options={clientOptions()}
                            value={assignedContacts}
                            onChange={(newValue) => {
                              const value = newValue as SelectOptionType[];
                              setAssignedContacts(value);
                            }}
                            inputValue={userValue}
                            onChangeInput={setUserValue}
                            isMulti
                            isLoading={isLoadingContacts}
                          />
                        </div>
                        <div
                          className={"cursor-pointer text-base text-gray-500"}
                          onClick={() => setViewList(true)}>
                          List view
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isError && (
                <div className={"text-md mb-2 text-red-500"}>
                  Something went wrong
                </div>
              )}
              <div className={"flex justify-start gap-4"}>
                <Button
                  onClick={onSubmit}
                  label={"Update"}
                  colorType={"secondary"}
                  extraClasses={"normal-case"}
                />
                <OutlineButton
                  colorType={"neutral"}
                  label={"Cancel"}
                  onClick={closeEdit}
                  extraClasses={"normal-case"}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {viewList && (
        <ViewContactList
          contacts={contacts}
          isActive={viewList}
          user={user}
          isLoadingContacts={isLoadingContacts}
          assignedContacts={assignedContacts}
          setAssignedContacts={setAssignedContacts}
          userValue={userValue}
          setUserValue={setUserValue}
          closeEdit={() => setViewList(false)}
        />
      )}
    </div>
  );
};
