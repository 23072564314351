import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Button from "../../../components/button/Button";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { useGetUserQuery } from "../../../slices/UserSlice";
import {
  useGetHomeMyWorkStatsQuery,
  useGetHomeWorksQuery,
} from "../../../slices/WorkSlice";
import { WorkTableSort } from "../../works/components/types/work-table";
import { WorkList } from "../../works/components/WorkList";
import { AddWorkModal } from "../../works/modals/AddWork.modal";
import { HomeStats } from "./HomeStats";
import { Filters } from "../../works/pages/AllWorks";
import CustomSelect from "../../../components/select/Select";
import { SelectOptionType } from "../../../types/types";
import { useGetWorkTypesQuery } from "../../../slices/WorkTypeSlice";
import { useGetStatusesQuery } from "../../../slices/StatusesSlice";
import { WorkModel } from "../../../factories/works/models/work.model";

export const HomeUserWorks: FC = () => {
  const params = useParams<{ userId: string }>();

  const { data: user, isLoading: isLoadingUser } = useGetUserQuery({
    userId: params?.userId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get("home-tab") as HomeTab;
  const activeFilter = searchParams.get("home-filter") as HomeFilter;
  const page = +(searchParams.get("page") || 1);
  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";

  const [filters, setFilters] = useState<Filters>({
    customerIds: [],
    workName: [],
    userIds: [],
    workStatuses: [],
    worktypeIds: [],

    startDates: [],
    dueDates: [],
    fromDate: null,
    toDate: null,
  });
  const [isAddWork, setIsAddWork] = useState(false);
  const [searchWorks, setSearchWorks] = useState("");

  const {
    data: stats,
    isLoading: isLoadingStats,
    isError,
  } = useGetHomeMyWorkStatsQuery({
    orgId: getLinkedFirm()?.orgId || "",
    userId: params?.userId || "",
  });

  const { data: works, isLoading: isLoadingWorks } = useGetHomeWorksQuery({
    pageNumber: page,
    pageSize: 20,
    sortBy: sortBy,
    sortOrder: sortOrder,
    dueDates:
      activeFilter === HomeFilter.OverDue ? HomeFilter.OverDue : activeTab,
    status: filters.workStatuses.map((item) => item.label) || [],
    assignedUserId: params?.userId || "",
    orgId: getLinkedFirm()?.orgId || "",
  });
  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });
  const { data: statuses, isLoading: isLoadingStatuses } = useGetStatusesQuery({
    orgId: getLinkedFirm()?.orgId || "",
  });

  useEffect(() => {
    if (!activeTab) {
      setSearchParams({
        "home-tab": HomeTab.ThisWeek,
        page: page?.toString() || "",
        sortBy: sortBy?.toString() || "",
        sortOrder: sortOrder?.toString() || "",
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setSearchParams({
      "home-tab": activeTab || "",
      page: "1",
      sortBy: WorkTableSort.WorkName,
      sortOrder: "asc",
    });
  }, []);

  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });
    }
    return options;
  };
  const statusesOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (statuses) {
      statuses?.forEach((item) => {
        options.push({
          label: item?.name,
          value: item?.id,
          color: item?.color,
          isBold: true,
        });
        if (
          item?.workTypeSubStatuses &&
          item?.workTypeSubStatuses?.length > 0
        ) {
          item?.workTypeSubStatuses?.forEach((subItem) => {
            options.push({
              label: subItem?.subStatus,
              value: subItem?.id,
              color: item?.color,
            });
          });
        }
      });
    }
    return options;
  };

  if (isLoadingStats || isLoadingUser) {
    return <LottieLoading />;
  }

  if (isError) {
    return null;
  }

  return (
    <>
      <div className={"grid h-full w-full grid-rows-[max-content_1fr] gap-6"}>
        <HomeStats
          thisWeek={stats?.thisWeek?.total || 0}
          nextWeek={stats?.nextWeek?.total || 0}
          later={stats?.later?.total || 0}
          allOpen={stats?.allOpen?.total || 0}
          completed={stats?.completed || 0}
        />
        <WorkList
          works={works || []}
          statusFilter={filters.workStatuses.map((i) => i.label)}
          isLoading={isLoadingWorks || isLoadingUser}
          //totalWorks={getTotalWorks() || 0}
          hasPages>
          <div
            className={
              "grid grid-cols-[1fr_max-content] items-start gap-2 px-8 pt-6"
            }>
            <div className={"flex items-center gap-4"}>
              <div className={"font-medium"}>
                {user?.userProfile?.firstName} {user?.userProfile?.lastName}
              </div>
              <div className={"flex gap-4 pb-4"}>
                <div className={"w-[150px] min-w-[100px]"}>
                  <div className={"text-sm font-semibold text-gray-400"}>
                    Work Type
                  </div>
                  <CustomSelect
                    options={workTypeOptions()}
                    value={filters?.worktypeIds}
                    onChange={(newValue) => {
                      const value = newValue as SelectOptionType[];
                      setFilters((prev) => ({
                        ...prev,
                        worktypeIds: value,
                      }));
                    }}
                    small
                    inputValue={searchWorks}
                    onChangeInput={setSearchWorks}
                    isMulti
                    isLoading={isLoadingWorkTypes}
                    workTable
                    placeholder={"All"}
                    width={"250px"}
                  />
                </div>
                <div className={"w-[150px] min-w-[100px]"}>
                  <div className={"text-sm font-semibold text-gray-400"}>
                    Status
                  </div>
                  <CustomSelect
                    options={statusesOptions()}
                    value={filters.workStatuses}
                    onChange={(newValue) => {
                      const newStatuses = Array.isArray(newValue)
                        ? newValue
                        : [newValue];
                      setFilters((prev) => ({
                        ...prev,
                        workStatuses: newStatuses,
                      }));
                    }}
                    workTable
                    small
                    isLoading={isLoadingStatuses}
                    placeholder={"All"}
                    width={"250px"}
                  />
                </div>
              </div>
            </div>
            <Button
              buttonType={"button"}
              label={"Add work"}
              onClick={() => setIsAddWork(true)}
            />
          </div>
        </WorkList>
      </div>
      {isAddWork && (
        <AddWorkModal isOpen={isAddWork} onClose={() => setIsAddWork(false)} />
      )}
    </>
  );
};
