import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import {
  getInitials,
  getInitialsFromFullName,
} from "../../../../../../constants/constants";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { navigateToContact } from "../../../../../contacts/utils/navigateToContacts";
import { Card } from "../../../../../../components/card/Card";
import { WorkStatus } from "../work-main/WorkStatus";
import { useGetWorkTasksQuery } from "../../../../../../slices/WorkTasksSlice";
import { getCompletedTasks } from "../../../../utils/workCharts";
import moment from "moment/moment";
import { StyledDatePicker } from "../../../../../../components/datepicker/datepicker";
import { useUpdateWorkDatesMutation } from "../../../../../../slices/WorkSlice";

import Button from "../../../../../../components/button/Button";

import { Slider } from "baseui/slider";
import { WorkTimer } from "../work-main/WorkTimer";
import {
  elapsedTimer,
  pauseTimer,
} from "../../../../../../services/TimerService";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/redux";
import AddTimer from "../../../../../main/AddTimer";
import { getLinkedFirm } from "../../../../../../sessionStorage/sessionStorage";

type Props = {
  work: WorkDetailsModel;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
};

export const WorkDetailsInfo: FC<Props> = ({ work, setIsEdit }) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const navigate = useNavigate();
  const startDate = moment(work.startDate).toDate();
  const dueDate = moment(work.dueDate).toDate();

  const { timer } = useAppSelector((state) => state.timerReducer);
  const dispatch = useAppDispatch();

  const { data: workTasks } = useGetWorkTasksQuery({
    workId: work.id || "",
  });
  const [updateWorkDates, { data: updatedWork }] = useUpdateWorkDatesMutation();

  const [previewDates, setPreviewDates] = useState<{
    startDate: Date;
    dueDate: Date;
  } | null>(null);

  const [isOpenAddTimer, setIsOpenAddTimer] = useState(false);
  const closeAddTimer = () => {
    setIsOpenAddTimer(false);
  };

  const getMinDate = () => {
    if (!workTasks || workTasks.length === 0) return undefined;
    return workTasks
      .map((task) => new Date(task.dueDate))
      .reduce((maxDate, currentDate) => {
        return currentDate > maxDate ? currentDate : maxDate;
      });
  };

  const defaultValues = {
    workId: work?.id,
    createdAt: work?.createdAt,
    relayId: work?.relayId,
  };

  const onChangeStartDate = (date: Date) => {
    setPreviewDates({ startDate: moment(date).toDate(), dueDate: dueDate });
    updateWorkDates({
      ...defaultValues,
      startDate: moment(date).toDate(),
      endDate: work?.dueDate,
    });
  };

  const onChangeDueDate = (date: Date) => {
    setPreviewDates({ startDate: startDate, dueDate: moment(date).toDate() });
    updateWorkDates({
      ...defaultValues,
      startDate: work?.startDate,
      endDate: moment(date).toDate(),
    });
  };
  const showDates = (): { startDate: Date; dueDate: Date } => {
    if (
      moment(updatedWork?.startDate).toDate() === startDate &&
      moment(updatedWork?.dueDate).toDate() === dueDate
    ) {
      return { startDate, dueDate };
    }
    if (previewDates) {
      return previewDates;
    }
    if (updatedWork) {
      return {
        startDate: moment(updatedWork?.startDate).toDate(),
        dueDate: moment(updatedWork?.dueDate).toDate(),
      };
    }
    return { startDate, dueDate };
  };

  const onClickStopTimer = () => {
    dispatch(pauseTimer(timer?.id || ""));
    setIsOpenAddTimer(true);
  };

  const width = workTasks
    ? Math.trunc(100 / (workTasks?.length / getCompletedTasks(workTasks)))
    : 0;

  return (
    <Card extraClasses={"shadow-box px-4 max-w-full"}>
      <div>
        <div className={"flex justify-between pt-4"}>
          <div className={"text-[20px] font-semibold"}>Details</div>
          <div
            className={
              "flex cursor-pointer items-center text-base text-[#7B66F0]"
            }
            onClick={() => setIsEdit(true)}>
            Edit Details
          </div>
        </div>
        <div className={"border-b border-gray-200 py-2"}>
          <div
            className={
              "grid grid-cols-2 grid-rows-3 gap-x-4 gap-y-4 py-4 text-base"
            }>
            <div className={"text-base text-[#2B333CCC]"}>Status</div>
            <div className={"flex justify-end"}>
              <WorkStatus work={work} positionType={"right-bottom"} />
            </div>
            <div className={"text-base text-[#2B333CCC]"}>Status Changed</div>
            <div className={"flex justify-end text-sm font-semibold"}>
              {work?.statusUpdatedAt
                ? moment(work?.statusUpdatedAt).format("MMM DD, YYYY")
                : "-"}
            </div>
            <div className={"text-base text-[#2B333CCC]"}>Progress</div>
            <div className={"flex justify-end"}>
              <div
                className={
                  "w-fit rounded-md border border-[#EAEDF3A3] bg-[#F8F9FB] px-2 text-base font-[500] text-[#2B333C]"
                }>
                {getCompletedTasks(workTasks || []) +
                  "/" +
                  workTasks?.length +
                  " Subtasks"}
              </div>
            </div>
          </div>
          <div>
            <div className={"h-[6px] w-[100%] rounded-md bg-[#F4F6F8]"}>
              <Slider
                max={100}
                value={[width]}
                onChange={() => null}
                overrides={{
                  InnerTrack: {
                    style: () => ({
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      height: "6px",
                      background: `linear-gradient(to right, #43BB65 ${width}%, #F4F6F8 ${width}%)`,
                      borderBottomRightRadius: "6px",
                      borderTopRightRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopLeftRadius: "6px",
                    }),
                  },
                  Track: {
                    style: () => ({
                      cursor: "default",
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                  },
                  Thumb: () => null,
                  TickBar: () => null,
                }}
              />
            </div>
          </div>
        </div>
        <div className={"border-b border-gray-200"}>
          <div
            className={
              "grid grid-cols-2 grid-rows-3 items-center gap-x-4 gap-y-4 py-4 text-base"
            }>
            <div className={"text-base text-[#2B333CCC]"}>Start date</div>
            <div className={"flex w-full justify-end"}>
              <div className={"flex w-fit items-center justify-end"}>
                <StyledDatePicker
                  value={showDates().startDate}
                  onChange={onChangeStartDate}
                  maxDate={showDates().dueDate}
                  format={"dd MMMM, yyyy"}
                  styleType={"ghost"}
                  width={"fit-content"}
                  textAlign={"end"}
                />
              </div>
            </div>

            <div className={"text-base text-[#2B333CCC]"}>Due date</div>
            <div className={"flex w-full justify-end"}>
              <div className={"flex w-fit items-center justify-end"}>
                <StyledDatePicker
                  value={showDates().dueDate}
                  onChange={onChangeDueDate}
                  styleType={"ghost"}
                  minDate={getMinDate()}
                  format={"dd MMMM, yyyy"}
                  width={"fit-content"}
                  textAlign={"end"}
                />
              </div>
            </div>

            <div className={"text-base text-[#2B333CCC]"}>Assignee</div>
            <div className={"flex items-center justify-end"}>
              <div className={"flex items-center gap-2"}>
                <AvatarPlaceholder
                  size={"super-small"}
                  type={"custom"}
                  avatarCustomColor={work?.assignedUser?.userProfile?.color}
                  label={getInitials(
                    work?.assignedUser?.userProfile?.firstName,
                    work?.assignedUser?.userProfile?.lastName,
                  )}
                />
                <div className={"text-sm font-semibold"}>
                  {work?.assignedUser?.userProfile?.firstName}{" "}
                  {work?.assignedUser?.userProfile?.lastName}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"border-b border-gray-200"}>
          <div
            className={
              "grid grid-cols-2 grid-rows-3 items-center gap-x-4 gap-y-4 py-4 text-base"
            }>
            <div className={"text-base text-[#2B333CCC]"}>Client</div>
            <div className={"flex items-center justify-end gap-2"}>
              <AvatarPlaceholder
                type={work?.customer?.businessContactId ? "purple" : "blue"}
                size={"super-small"}
                label={getInitialsFromFullName(work?.customer?.name)}
              />
              <div
                className={
                  "cursor-pointer text-sm font-semibold hover:underline"
                }
                onClick={() =>
                  navigate(
                    navigateToContact({
                      type: work?.customer?.businessContactId
                        ? "organization"
                        : "contact",
                      customerId: work?.customerId || "",
                      id: work?.customer?.businessContactId || "",
                    }),
                  )
                }>
                {work?.customer?.name}
              </div>
            </div>
            <div className={"text-base text-[#2B333CCC]"}>Work type</div>
            <div
              className={"flex items-center justify-end text-sm font-semibold"}>
              {work?.service?.name}
            </div>
            <div className={"text-base text-[#2B333CCC]"}>Recurring</div>
            <div className={"flex items-center justify-end"}>
              <div className={"w-fit text-sm font-semibold"}>
                {work?.repeatTypeEnum?.repeatType}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={"grid grid-cols-[6fr_1fr_6fr] pt-4"}>
            <WorkTimer work={work} />
            <div></div>
            <div className={"pb-4"}>
              <Button
                buttonType={"button"}
                disabled={
                  !(
                    timer?.timerStatus === "started" ||
                    timer?.timerStatus === "paused"
                  )
                }
                label={"Stop"}
                size={"custom"}
                onClick={onClickStopTimer}
                fullWidth
                extraClasses={
                  "normal-case min-h-fit py-0 p-0 px-4 h-8 w-full max-w-full"
                }
              />
            </div>
          </div>
          {isOpenAddTimer && (
            <AddTimer isOpen={isOpenAddTimer} closeModal={closeAddTimer} />
          )}
        </div>
      </div>
    </Card>
  );
};
