import { Pagination } from "baseui/pagination";
import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useSearchParams } from "react-router-dom";

import { LottieLoading } from "../../../components/graphics/LottieLoading";
import { WorkModel } from "../../../factories/works/models/work.model";
import { Card } from "../../../components/card/Card";
import { WorkTable } from "./WorkTable";
import { WorkTableSort } from "./types/work-table";
import {
  HomeFilter,
  HomeTab,
} from "../../../factories/works/models/home-enums";
import { SelectOptionType } from "../../../types/types";

type Props = {
  works: WorkModel[];
  isLoading?: boolean;
  hasPages?: boolean;
  children?: JSX.Element;
  myWorkHome?: boolean;
  filter?: HomeTab | HomeFilter.OverDue;
  setArchiveWorks?: Dispatch<SetStateAction<WorkModel[]>>;
  archiveWorks?: WorkModel[];
  statusFilter?: string[];
};

type Params = {
  sortBy: string;
  sortOrder: string;
  page: string;
  "home-tab"?: string;
  "home-filter"?: string;
  statusName?: string;
  workType?: string;
  assignee?: string;
};

export const WorkList: FC<Props> = ({
  works,
  isLoading,
  hasPages,
  children,
  myWorkHome,
  filter,
  setArchiveWorks,
  archiveWorks,
  statusFilter,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("home-tab");
  const activeFilter = searchParams.get("home-filter");

  const page = +(searchParams.get("page") || 1);
  const totalPages = Math.ceil(works?.[0]?.totalRecords / 20) || 1;

  const sortBy = searchParams.get("sortBy") as WorkTableSort;
  const sortOrder = searchParams.get("sortOrder") as "asc" | "desc";
  const statusName = searchParams.get("statusName") || "";
  const workType = searchParams.get("workType") || "";
  const assignee = searchParams.get("assignee") || "";

  const getParams = (nextPage: number): Params => {
    const params: Params = {
      sortBy,
      sortOrder,
      page: (nextPage || 1).toString(),
      "home-tab": activeTab || "",
      "home-filter": activeFilter || "",
      statusName: statusName || "",
      workType: workType,
      assignee: assignee,
    };
    if (!activeTab) {
      delete params?.["home-tab"];
    }
    if (!statusName) {
      delete params?.statusName;
    }
    if (!workType) {
      delete params?.workType;
    }
    if (!assignee) {
      delete params?.assignee;
    }
    return params;
  };

  return (
    <Card extraClasses={"grid grid-rows-1"}>
      <div
        className={classNames(
          "grid h-full w-full gap-4",
          hasPages
            ? "grid-rows-[max-content_1fr_max-content]"
            : "grid-rows-[max-content_1fr]",
        )}>
        {children}
        {isLoading ? (
          <div>
            <LottieLoading />
          </div>
        ) : (
          <div className={"max-h-full w-full overflow-x-auto px-6"}>
            <WorkTable
              works={works}
              statusFilter={statusFilter}
              myWorkHome={myWorkHome}
              filter={filter}
              setArchiveWorks={setArchiveWorks}
              archiveWorks={archiveWorks}
            />
          </div>
        )}
        {hasPages && !!works?.length && (
          <div>
            <Pagination
              numPages={totalPages}
              currentPage={page}
              onPageChange={({ nextPage }) => {
                if (!isLoading) {
                  setSearchParams(getParams(nextPage));
                }
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
