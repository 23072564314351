import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as devConfig from "../app/configs/devConfig";
import { getAuthHeader } from "../app/globalState/GlobalAuthState";
import { Moment } from "moment";
interface ITimer {
  id: string;
  workId: string;
  customerId: string;
  duration: number;
  timerStatus: "started" | "paused" | "ended";
  workTypeId: null;
  work: any;
  contactName: string;
  businessContactId: string;
  contactId: string;
  assignedUserId: string;
  notes: string;
  assignedUser: any;
  contact: any;
  businessContact: any;
}
interface IInitialState {
  timer: ITimer | null;
  duration: number;
  isLoading: boolean;
  error: string;
}
const initialState: IInitialState = {
  duration: 0,
  timer: null,
  isLoading: false,
  error: "",
};

export const endTimer = createAsyncThunk(
  "timer/endTimer",
  async (
    body: {
      id: string;
      duration: number;
      date: Moment;
      workId: string;
      notes: string;
      assignedUserId: string | null;
      workTypeId: string | null;
    },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/end-timer`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        body: JSON.stringify(body),
        method: "POST",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const pauseTimer = createAsyncThunk(
  "timer/pauseTimer",
  async (timeId: string, { rejectWithValue }) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/pause-timer?timeId=${timeId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "PUT",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const startTimer = createAsyncThunk(
  "timer/startTimer",
  async (
    { workId, customerId }: { workId: string; customerId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/start-timer?workId=${workId}&customerId=${customerId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);
export const elapsedTimer = createAsyncThunk(
  "timer/allTimers",
  async (
    { orgId, userId }: { orgId: string; userId: string },
    { rejectWithValue },
  ) => {
    const response = await fetch(
      `${devConfig.getServerBaseUrlForEnv()}/api/time/all-timers?orgId=${orgId}&userId=${userId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getAuthHeader().Authorization,
        },
        method: "GET",
      },
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return rejectWithValue("Error");
    }
  },
);

export const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    setTimer(state, action: PayloadAction<ITimer | null>) {
      state.timer = action.payload;
    },
    setTimerDuration(state, action: PayloadAction<number>) {
      if (state.timer?.timerStatus) {
        state.duration = action.payload;
      }
    },
    increaseDuration(state) {
      if (state.timer?.timerStatus) {
        state.duration = state.duration + 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startTimer.fulfilled, (state, action) => {
        state.timer = action.payload;
        state.duration = action.payload.duration;
      })
      .addCase(pauseTimer.fulfilled, (state, action) => {
        state.timer = action.payload;
        state.duration = action.payload.duration;
      })
      .addCase(elapsedTimer.fulfilled, (state, action) => {
        if (action.payload.timerStatus) {
          state.timer = action.payload;
        }
      });
  },
});

export const { setTimerDuration, setTimer, increaseDuration } =
  timerSlice.actions;
export default timerSlice.reducer;
